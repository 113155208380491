<template>
  <div>
  <section
    class="bg-img"
    :style="{ backgroundImage: `url(${patternVue2})` }"
  >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12" v-if="AboutInfo">
            <div
              class="white-bg box-shadow px-5 py-5 sm-px-3 sm-py-3 xs-px-2 xs-py-2 bg-contain bg-pos-l radius bg-img"
              :style="{ backgroundImage: `url(${bgVue})` }"
            >
              <div class="row">
                <div class="col-lg-12 col-md-12 text-center mx-auto">
                  <div class="section-title">
                    <h6>
                      {{ $t("rowadAltahol") }}
                    </h6>
                    <h2
                      class="mb-4 animated fadeInUp duration-2"
                      v-html="AboutInfo.title_ar"
                    ></h2>
                  </div>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 mt-5 mt-lg-0">
                  <p
                    class="animated fadeInUp delay-1 duration-2"
                    v-html="resultsub"
                  ></p>
                  <div
                    class="animated fadeInUp delay-2 duration-2 mr-auto text-revers"
                  >
                  <router-link
                    class="btn btn-theme"
                    to="/about"
                  >
                  <span> {{ $t("readMore") }} </span>
                  </router-link>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <img
                    class="img-fluid animated zoomIn delay-2 duration-2"
                    :src="AboutInfo.image"
                    alt="شل"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>

  </div>
</template>

<script>
import patternVue2 from "@/assets/frontend/plugins/images/pattern/02.png";
import bgVue from "@/assets/frontend/plugins/images/bg/01.png";
export default {
  name: "IndexPeage",
  props: {
    indexData: {
      type: Object,
      default: () => ({}),
    },
    msg: String,
  },
  data() {
    return {
      modifiedIndexData: {},
      patternVue2,
      bgVue,
    };
  },
  computed: {
    AboutInfo() {
      return this.indexData.aboutinfo;
    },
    resultsub() {
      return this.indexData.resultsub;
    },
  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.indexData };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
