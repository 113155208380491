<!-- @format -->

<template>
  <footer
    class="footer white-bg z-index-1 overflow-hidden bg-contain bg-img"
    :style="{ backgroundImage: `url(${patternVue})` }"
    v-if="Setting"
  >
    <div class="round-p-animation"></div>
    <div class="primary-footer">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-lg-4">
            <div
              class="ht-theme-info bg-contain bg-pos-r h-100 dark-bg text-white"
            >
              <div class="footer-logo">
                <router-link to="/">
                  <img
                    class="img-fluid"
                    :src="Setting.style_logo"
                    :alt="Setting.title"
                  />
              </router-link>
              </div>
              <p class="mb-3">{{ resultsubFooter }}</p>
              <div
                class="animated fadeInUp delay-2 duration-2 mr-auto text-revers"
              >
              <router-link to="/about" class="btn btn-theme">
                            <span>{{ $t("readMore") }}</span>
                          </router-link>
                
              </div>
            </div>
          </div>
          <div class="col-lg-8 py-8 px-5">
            <div class="row">
              <div class="col-lg-6 col-md-6 footer-list">
                <h4 class="title">{{ $t("footerLinks") }}</h4>
                <div class="row">
                  <div class="col-sm-5">
                    <ul class="list-unstyled">
                      <li>
                        <router-link to="/about">
                            <span>{{ $t("about_us") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/Why_us">
                            <span>{{ $t("why_us") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/Methodology">
                            <span>{{ $t("methodology") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/Distinction">
                            <span>{{ $t("distinction") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/Skills">
                            <span>{{ $t("skills") }}</span>
                          </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-7">
                    <ul class="list-unstyled">
                      
                      <li>
                        <router-link to="/Services">
                            <span>{{ $t("services") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/Projects">
                            <span>{{ $t("projects") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/DigitalTransformation">
                            <span>{{ $t("digitalTransformation") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/ApplicationDevelopment">
                            <span>{{ $t("applicationDevelopment") }}</span>
                          </router-link>
                      </li>
                      <li>
                        <router-link to="/contact_us">
                            <span>{{ $t("contact_us") }}</span>
                          </router-link>
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5 mt-md-0">
                <h4 class="title">{{ $t("contact_usFooter") }}</h4>
                <ul class="media-icon list-unstyled">
                  <li>
                    <p class="mb-0">
                      <i class="fa fa-map-marker"></i>
                      {{ Setting.address_ar }}
                    </p>
                  </li>
                  <li>
                    <i class="fa fa-envelope"></i>
                    <a :href="'mailto:' + Setting.email">
                      {{ Setting.email }}
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-phone"></i>
                    <a :href="'tel: ' + Setting.phone">
                      <bdo dir="ltr">{{ Setting.phone }}</bdo></a
                    >
                  </li>
                  <li>
                    <i class="fab fa-whatsapp"></i>
                    <a :href="'https://wa.me/' + Setting.whatsapp">
                      {{ $t("contactWhatsapp") }} !</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="socialfooter" translate="no">
      <div class="hexagon-wrapper">
        <div class="hexagon">
          <a :href="Setting.facebook">
            <i class="fab fa-facebook-f"></i>
          </a>
        </div>
      </div>
      <div class="hexagon-wrapper">
        <div class="hexagon">
          <a :href="Setting.twitter">
            <i class="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      <div class="hexagon-wrapper">
        <div class="hexagon">
          <a :href="Setting.instagram">
            <i class="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div class="hexagon-wrapper">
        <div class="hexagon">
          <a :href="'https://wa.me/' + Setting.whatsapp"
            ><i class="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
      <div class="hexagon-wrapper">
        <div class="hexagon">
          <a :href="Setting.linkedin">
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="secondary-footer">
      <div class="container">
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-6">
              <span>
                {{ $t("AllRightsReserved") }} {{ Setting.title }} ©
                {{ currentYear }}
                .
              </span>
            </div>
            <div class="col-md-6 text-md-end sm-mt-2">
              <span>
                {{ $t("design_by") }} :
                <a href="http://ragmyat.com/">
                  {{ Setting.title }}
                </a></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!--footer end-->
</template>

<script>
import patternVue from "@/assets/frontend/plugins/images/pattern/01.png";
export default {
  name: "FooterSection",
  props: {
    SettingData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
  return {
      modifiedIndexData: {},
      patternVue,
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    Setting() {
      return this.SettingData?.Setting || {};
    },
    resultsubFooter() {
      return this.SettingData?.resultsubFooter || {};
    },
  },
  mounted() {
    // Modify the SettingData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.SettingData };
  },
};
</script>
