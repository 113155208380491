import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServeseView from "../views/ServeseView.vue";
import ProjectView from "../views/ProjectView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/Why_us",
    name: "Why_us",
    component: () =>import(/* webpackChunkName: "about" */ "../views/Why_usView.vue"),
  },
  {
    path: "/Methodology",
    name: "Methodology",
    component: () =>import(/* webpackChunkName: "about" */ "../views/MethodologyView.vue"),
  },
  {
    path: "/Distinction",
    name: "Distinction",
    component: () =>import(/* webpackChunkName: "about" */ "../views/DistinctionView.vue"),
  },
  {
    path: "/Skills",
    name: "Skills",
    component: () =>import(/* webpackChunkName: "about" */ "../views/SkillsView.vue"),
  },
  {
    path: "/DigitalTransformation",
    name: "DigitalTransformation",
    component: () =>import(/* webpackChunkName: "about" */ "../views/DigitalTransformationView.vue"),
  },
  {
    path: "/ApplicationDevelopment",
    name: "ApplicationDevelopment",
    component: () =>import(/* webpackChunkName: "about" */ "../views/ApplicationDevelopmentView.vue"),
  },
  {
    path: "/contact_us",
    name: "contact",
    component: () =>import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/Services",
    name: "Services",
    component: () =>import(/* webpackChunkName: "Services" */ "../views/ServicesView.vue"),
  },
  {
    path: "/Projects",
    name: "Projects",
    component: () =>import(/* webpackChunkName: "Projects" */ "../views/ProjectsView.vue"),
  },
  {
    path: "/Raqeeb",
    name: "Raqeeb",
    component: () =>import(/* webpackChunkName: "Raqeeb" */ "../views/RaqeebView.vue"),
  },
  {
    path: "/QFatora",
    name: "QFatora",
    component: () =>import(/* webpackChunkName: "QFatora" */ "../views/QFatoraView.vue"),
  },
  {
    path: "/Servese/:id",
    name: "Servese",
    component: ServeseView,
    // component: ()=>import("../views/ServeseView.vue")
  },
  {
    path: "/Project/:id",
    name: "Project",
    component: ProjectView,
    // component: ()=>import("../views/ProjectView.vue")
  },
];

const base = process.env.BASE_URL || '/';
const router = createRouter({
  history: createWebHistory(base),
  routes,
});

export default router;
