import "@/assets/frontend/plugins/css/bootstrap.min.css";
import "@/assets/frontend/plugins/css/animate.css";
import "@/assets/frontend/plugins/css/fontawesome-all.css";
import "@/assets/frontend/plugins/css/themify-icons.css";
import "@/assets/frontend/plugins/css/magnific-popup/magnific-popup.css";
import "@/assets/frontend/plugins/css/owl-carousel/owl.carousel.css";
import "@/assets/frontend/plugins/css/spacing.css";
import "@/assets/frontend/plugins/css/base.css";
import "@/assets/frontend/plugins/css/shortcodes.css";
import "@/assets/frontend/plugins/css/style.css";
import "@/assets/frontend/plugins/css/responsive.css";
import "@/assets/frontend/plugins/assets/vendor/animate.css/animate.min.css";
import "@/assets/frontend/plugins/assets/vendor/aos/aos.css";
import "@/assets/frontend/plugins/assets/css/style.css";
import "@/assets/frontend/plugins/assets/css/styl.css";
import "@/assets/frontend/plugins/css/custome.css";
import { createApp } from "vue";
import App from "./App.vue";
import i18n from './i18n';
import router from "./router";
import store from "./store";
import 'process/browser';
import axios from 'axios';

// eslint-disable-next-line no-undef
const base_url = `${APP_URL}/api/`;
// const base_url = process.env.MIX_API_URL
// console.log(base_url)
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
//   baseURL: '/api/',
  baseURL: base_url,
  withCredentials: true,
 
  // baseURL: 'http://127.0.0.1:8000/api/',
  // timeout: 1000,
  headers:{
    common:{
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
  }
  }
})



window.axios = axiosIns;

// App.prototype.$axios = axiosIns
// const metaManager = createMetaManager();
// createApp(App).component('vue-owl-carousel', VueOwlCarousel).use(i18n).use(store).use(router).mount("#app");
const app = createApp(App);
app.config.globalProperties.$axios = axiosIns;
app.use(i18n);
app.use(store);
app.use(router);
app.mount("#app");
