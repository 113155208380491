<template>
  <div class="skills">
    <div class="page-content bg-white">
      <div class="page-title-area" :style="{ backgroundImage: `url('${aboutusbanner}')` }">
        <div class="container">
          <div class="page-title-content">
            <h2>{{ Project.title_ar }}</h2>
            <ul>
              <li>
                <router-link to="/">{{ $t('home') }}</router-link>
              </li>
              <li>
                <router-link to="/Projects">{{ $t('projects') }}</router-link>
              </li>

              <li class="tg-active">{{ Project.title_ar }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--Project start-->
    <section  v-if="Project && Setting">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="case-images">
                        <img class="img-fluid w-100" :src="Project.banner" :alt="Project.title_ar">
                    </div>
                </div>
                <div class="col-12">
                    <div class="box-shadow">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="project-details px-3 pt-4 pb-2">
                                    <h4> {{   $t('project_description_ar') }}  </h4>
                                    <div class="lead text-black font-italic mb-4" v-html="Project.description_ar">
                                    </div>

                                    <h4> {{   $t('project_advantages_ar') }}  </h4>
                                    <div v-html="Project.advantages_ar"></div>

                                </div>
                                <div class="animated fadeInUp delay-2 duration-2 mr-auto text-revers my-3">
                                                <a class="btn btn-theme" :href="Project.tags"><span> {{   $t('wibsiteVisit') }}  </span></a>
                                            </div>
                            </div>
                            <div class="col-lg-5 col-md-12">
                                <div class="theme-bg h-100 align-item-middle">
                                    <ul class="portfolio-meta list-unstyled px-5 md-px-3 py-3">
                                        <li class="mb-4"><i class="flaticon-user"></i> <span> {{   $t('project_client_ar') }} : </span>
                                             {{ Project.client_ar  }}
                                    </li>
                                        <li class="mb-4"><i class="fa fa-phone"></i> <span>
                                            {{   $t('project_phone') }} :
                                        </span>
                                        <bdo dir="ltr" v-html="Project.phone"> </bdo>
                                            </li>
                                            <li class="mb-4"><i class="fa fa-envelope"></i> <span>
                                                {{   $t('project_email') }} :
                                        </span>
                                        <a  :href="'mailto:' + Project.email" class="text-white"> <bdo dir="ltr"> {{ Project.email }} </bdo></a>

                                            </li>
                                        <li class="mb-4"><i class="flaticon-checked"></i> <span>
                                            {{   $t('project_project_delivery') }} :
                                        </span> {{ Project.project_delivery }} </li>
                                        <li><i class="flaticon-location"></i> <span>  {{   $t('project_location') }} :</span>
                                            {{ Project.location }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--portfolio slider start-->

    <section class="pt-0 pb-17 sm-pb-8" v-if="Projects">
        <div class="container">
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="section-title">
                        <h2 class="title"> {{   $t('lastProject') }} </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <carousel class="py-0 popup-gallery" v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="false">
                    <slide v-for="project in Projects" :key="project.id" class="owl-item">
                        <div class="portfolio-item">
                            <img class="img-fluid w-100" :src="project.image" :alt="Setting.title">
                            <div class="portfolio-title"> <span> {{ $t('brandName') }}  </span>
                                <h4> {{ project.title_ar }} </h4>
                            </div>
                            <a class="popup-img" :href="project.banner" :alt="Setting.title" :title="project.title_ar"> <i
                                    class="flaticon-plus"></i>
                            </a> 
                            <router-link :to="`/Project/${project.id}`" class="popup-link">  {{ $t('moreDetails') }} </router-link>
                            
                        </div>
                      </slide>
                      <template #addons="{ slidesCount }">
                        <navigation v-if="1<0" />
                        <pagination  v-if="slidesCount > 1" />
                      </template>
                  </carousel>
                </div>
            </div>
        </div>
    </section>

    <!--portfolio slider end-->
   
    
  </div>
</template>

<script>
import aboutusbanner from "@/assets/frontend/plugins/images/banner/aboutusbanner.jpg";


import pattern7 from "@/assets/frontend/plugins/images/pattern/07.png";

import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import $ from 'jquery';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'magnific-popup/dist/magnific-popup.css';
export default {
  name: "ProjectView",
  props: {
    SettingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    // VueOwlCarousel
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      // carousel settings
      settings: {
      itemsToShow: 1,
      snapAlign: 'start',
      dir: 'rtl' ,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 600 and up
      600: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // 800 and up
      800: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      // 992 and up
      992: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1024 and up
      1200: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1792 and up
      1792: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 1920 and up
      1920: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 2560 and up
      2560: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    },
      indexData: {},
      aboutusbanner,
      pattern7,
      modifiedIndexData: {}
    };
  },
  created() {
    this.fetchData();
    // const id = this.$route.params.id;
    // axios
    // .get(`http://127.0.0.1:8000/api/Project/${id}`)
    //   .then(response => {
    //     this.indexData = response.data;
    //     // console.log(response.data);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(newId) {
        this.fetchData(newId);
      },
    },
  },
  computed: {

    Setting() {
      if (!this.SettingData || !this.SettingData.Setting) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.SettingData.Setting;
    },
    Project() {
      if (!this.indexData || !this.indexData.project) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.indexData.project;
    },
    Projects() {
      if (!this.indexData || !this.indexData.projects) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.indexData.projects;
    }
  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.indexData };
    this.initializeMagnificPopup();
  },
  methods: {
    fetchData(id) {
      id = id || this.$route.params.id;
      this.$axios
        .get(`Project/${id}`)
        .then((response) => {
          this.indexData = response.data;
          this.modifiedIndexData = { ...this.indexData };
        })
        .catch((error) => {
          console.error(error);
        });
    },
    initializeMagnificPopup() {
      $('.popup-gallery').magnificPopup({
        delegate: 'a.popup-img',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
            return item.el.attr('title') + '<small> بواسطة رقميات التحول  </small>';
          }
        }
      });

      if ($('.popup-youtube, .popup-vimeo, .popup-gmaps').length > 0) {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
      }
    }
  }
};
</script>
