<template>
  <section class="go aos-init aos-animate" data-aos="fade-up" data-aos-delay="200" style="background: none;">
    <h1 style="color: #0d2735; text-align: center;">{{ $t('wantToStart') }}</h1>
    <p>{{ $t('wantToStartContent') }}</p>

    <div class="container" style="margin-top: 5em; background: none;">
      <div class="row">
        <div class="col-lg-12 d-flex flex-column justify-content-center p-5">
          <div class="content-moving">
            <div class="moving-zone" ref="movingZone">
              <a href="#">
                <div class="popup" :style="popupStyle">
                  <div class="popup-content">
                    <div class="popup-text">
                      <h2>{{ $t('startNow') }}</h2>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    msg: String
  },
  data() {
    return {
      moveForce: 30,
      rotateForce: 25,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    popupStyle() {
      const moveX = ((this.mouseX - window.innerWidth / 2) / (window.innerWidth / 2)) * -this.moveForce;
      // const moveY = ((this.mouseY - window.innerHeight / 2) / (window.innerHeight / 2)) * -this.moveForce ;

      const rotateY = (this.mouseX / window.innerWidth) * this.rotateForce * 2 - this.rotateForce;
      // const rotateX = -(-(this.mouseY / window.innerHeight) * this.rotateForce * 2 - this.rotateForce);

      return {
        left: moveX + "px",
        top: 0 ,
        transform: `rotateX(0deg) rotateY(${rotateY}deg)`,
       
      };
    },
  },

  mounted() {
    this.$refs.movingZone.addEventListener("mousemove", this.handleMouseMove);
  },
  beforeUnmount() {
    this.$refs.movingZone.removeEventListener("mousemove", this.handleMouseMove);
  },
  methods: {
    handleMouseMove(event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    }
  }
};
</script>
