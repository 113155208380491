<template>
  <div class="Service">
    <div class="page-content bg-white">
      <div class="page-title-area" :style="{ backgroundImage: `url('${aboutusbanner}')` }">
        <div class="container">
          <div class="page-title-content">
            <h2>{{ Service.title_ar }}</h2>
            <ul>
              <li>
                <router-link to="/">{{ $t('home') }}</router-link>
              </li>
              <li>
                <router-link to="/Services">{{ $t('services') }}</router-link>
              </li>

              <li class="tg-active">{{ Service.title_ar }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--Project start-->
    <section v-if="Service && Setting">
            <div class="container">
                <div class="row">
                   
                    <div class="col-lg-8 col-md-12">
                        <div>
                            <div class="service-images">
                                <img class="img-fluid w-100" :src="Service.banner"
                                :alt="Service.title_ar +' - '+ Setting.title">
                            </div>
                            <div class="service-details mt-4">
                              <h2 class="title services pt-5">
                                    <div class="featured-item style-3 serv3 mr-auto">
                                        <div class="featured-icon">
                                            <div class="hexagon mx-auto text-center"><span>
                                                    <img class="img-fluid" :src="Service.image"
                                                    :alt="Service.title_ar+' - '+Setting.title"> </span>
                                            </div>
                                        </div>


                                    </div>
                                {{ Service.title_ar }}
                                </h2>
                                

                                <blockquote class="wp-block-quote" >
                                    <h5 v-html="Service.slug">
                                    </h5>

                                </blockquote>
                                <div class="mb-5 lead text-black" v-html="Service.description_ar">
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-md-12 sidebar mt-5 mt-lg-0">

                        <div class="widget mb-5" v-if="Services">
                            <div class="section-title mb-2">

                                <h3 class="title">  {{ $t('servicesTitele') }}   </h3>
                            </div>
                            <div class="sidebar-links">
                                <ul class="list-unstyled">
                                  <li v-for="serv in Services" :key="serv.id">
                                    <router-link  :to="'/Servese/' + serv.id">
                                    <span>{{ serv.title_ar }}</span>
                                  </router-link>
                                  </li>
                                  
                                </ul>
                            </div>
                        </div>

                        <div class="widget ">
                            <div class="section-title mb-2">

                                <h3 class="title">  {{ $t('contact_usFooter') }} </h3>
                            </div>
                            <div class="contact-main">
              <form @submit.prevent="submitForm" class="row g-4 needs-validation contactForm">
                <div class="messages" v-if="messageError"> {{ messageError }}</div>
                <div class="col-md-12">
                  <input v-model="formData.name" type="text" class="form-control" :placeholder="$t('yourName')" required spellcheck="false" data-ms-editor="true">
                  <div class="invalid-feedback">{{ $t('enterYourName') }}.</div>
                </div>
                <div class="col-md-12">
                  <input v-model="formData.phone" type="tel" class="form-control" :placeholder="$t('phone')" required>
                  <div class="invalid-feedback">{{ $t('enterYourPhone') }}</div>
                </div>
                <div class="col-md-12">
                  <input v-model="formData.email" type="email" class="form-control" :placeholder="$t('email')" required>
                  <div class="invalid-feedback">{{ $t('enterYourEmail') }}.</div>
                </div>
                <div class="col-md-12">
                  <input v-model="formData.subject" type="text" class="form-control" :placeholder="$t('subject')" required>
                  <div class="invalid-feedback">{{ $t('enterYourSubject') }}</div>
                </div>
                <div class="col-md-12">
                  <textarea v-model="formData.message" class="form-control" :placeholder="$t('writeYourMessage')" rows="3" required spellcheck="false" data-ms-editor="true"></textarea>
                  <div class="invalid-feedback">{{ $t('PleaseWriteYourMessage') }}.</div>
                </div>
                <div class="col-md-12 text-revers">
                  <button type="submit" class="btn btn-theme btn-radius" id="submitfromcontact">{{ $t('send') }}</button>
                </div>
              </form>
            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </section>
   
 
   
    
  </div>
</template>

<script>
import aboutusbanner from "@/assets/frontend/plugins/images/banner/aboutusbanner.jpg";


import pattern7 from "@/assets/frontend/plugins/images/pattern/07.png";
export default {
  name: "ServeseView",
  props: {
    SettingData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {

  },
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
        messageError:''
        // Other form fields
      },
      // carousel settings
      settings: {
      itemsToShow: 1,
      snapAlign: 'start',
      dir: 'rtl' ,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 600 and up
      600: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // 800 and up
      800: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      // 992 and up
      992: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1024 and up
      1200: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1792 and up
      1792: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 1920 and up
      1920: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 2560 and up
      2560: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    },
      indexData: {},
      aboutusbanner,
      pattern7,
      modifiedIndexData: {}
    };
  },
  created() {
    this.fetchData();
    // const id = this.$route.params.id;
    // axios
    // .get(`http://127.0.0.1:8000/api/Service/${id}`)
    //   .then(response => {
    //     this.indexData = response.data;
    //     // console.log(response.data);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(newId) {
        this.fetchData(newId);
      },
    },
  },
  computed: {

    Setting() {
      if (!this.SettingData || !this.SettingData.Setting) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.SettingData.Setting;
    },
    Service() {
      if (!this.indexData || !this.indexData.service) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.indexData.service;
    },
    Services() {
      if (!this.indexData || !this.indexData.services) {
        return {}; // return an empty object or an appropriate default value
      }
      return this.indexData.services;
    }
  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.indexData };
   
    
  },
  methods: {
    fetchData(id) {
      id = id || this.$route.params.id;
      this.$axios
        .get(`Service/${id}`)
        .then((response) => {
          this.indexData = response.data;
          this.modifiedIndexData = { ...this.indexData };
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitForm() {
      this.$axios.post('SubmitForm', this.formData)
        .then(response => {
          // Handle successful response
          console.log(response.data);
          // Reset form fields if needed
          this.formData.name = '';
          this.formData.phone = '';
          this.formData.email = '';
          this.formData.subject = '';
          this.formData.message = '';
          this.messageError = response.data.messageError;
          // Other form fields
        })
        .catch(error => {
          // Handle error response
          this.messageError = error;
          console.error(error);
        });
    },
  }
};
</script>
