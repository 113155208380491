<template>
  <div id="particles-js">
    <section id="hero" class="d-flex justify-cntent-center align-items-center">
      <div id="heroCarousel" class="container carousel-fade">
        <carousel class="py-0 popup-gallery" :autoplay="5000" :wrap-around="true" ref="carousel">
          <slide v-for="carousel in 3" :key="carousel" class="carousel-item active">
            <!-- Slide 1 -->

            <div class="carousel-container" v-if="carousel == 1">
              <img
                alt
                style="height:15vh;position: center; margin-bottom: 3%"
                :src="logo"
                class="animate__animated animate__fadeInDown logo1"
              />

              <h2 class="animate__animated animate__fadeInDown"> رقميات التحول لتقنية المعلومات</h2>
              <router-link
                to="/about"
                class="btn-get-started animate__animated animate__fadeInUp"
              >اعرف اكثر</router-link>
            </div>
            <!-- Slide 2 -->

            <div class="carousel-container" v-if="carousel == 2">
              <img
                alt
                style="height:15vh;position: center; margin-bottom: 3%"
                :src="logo"
                class="animate__animated logo1"
              />

              <h2
                class="animate__animated animate__fadeInDown"
              >استشارة التحول الرقمي وتطوير البرمجيات</h2>
              <router-link to="/about" class="btn-get-started animate__animated">اعرف اكثر</router-link>
            </div>

            <!-- Slide 3 -->

            <div class="carousel-container" v-if="carousel == 3">
              <img
                alt
                style="height:15vh;position: center; margin-bottom: 3%"
                :src="logo"
                class="animate__animated logo1"
              />

              <h2 class="animate__animated animate__fadeInDown">تصميم وتطوير الحلول الرقمية</h2>
              <router-link to="/about" class="btn-get-started animate__animated">اعرف اكثر</router-link>
            </div>
          </slide>
        </carousel>
        <!-- Navigation Buttons -->
        <button type="button" class="carousel-control-prev" @click="nextSlide">
          <img class="carousel-control-prev-icon bx bx-chevron-left" :src="nextl" />
        </button>
        <button type="button" class="carousel-control-next" @click="prevSlide">
          <img class="carousel-control-next-icon bx bx-chevron-right" :src="nextr" />
        </button>
      </div>
    </section>

    <particles></particles>
    <!-- Your component content -->
    
  </div>
</template>
<script>
import logo from "@/assets/frontend/plugins/assets/img/logo.png";
import nextl from "@/assets/frontend/plugins/assets/img/next-l.png";
import nextr from "@/assets/frontend/plugins/assets/img/next-r.png";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Particles from 'particles.js';

export default {
  name: "HeroSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    msg: String
  },
  components: {
    Particles,
    Carousel,
    Slide
  },
  data() {
    return {
      nextl,
      nextr,
      logo,
      particleSettings: {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000"
            },
            polygon: {
              nb_sides: 5
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true
      }
    };
  },
  methods: {
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    }
  }
};
</script>

<style scoped>
#particles-js {
  width: 100%;
  height: 100vh;
  background-color: #040d21;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

#hero {
  height: 100%;
  width: 100%;
  z-index: 99;
  position: relative;
  padding: 0;
  overflow: hidden;
}

</style>
