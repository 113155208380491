<template>
  <!--project start-->
<section class="overflow-hidden py-5" v-if="projects">
    <div class="container-fluid p-0">
        <div class="row text-center">
            <div class="col-lg-8 col-md-12 mx-auto">
                <div class="section-title">
                    <h6> {{ $t('newRagmyat') }} </h6>
                    <h2 class="title"> {{ $t('lastProject') }}   </h2>

                </div>
            </div>
        </div>
        <div class="row" >
            <div class="col-md-12">
                <div class=" " >
                  <carousel class="py-0 popup-gallery" v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="false">
                    <slide v-for="project in projects" :key="project.id" class="owl-item">
                        <div class="portfolio-item">
                            <img class="img-fluid w-100" :src="project.image" :alt="project.title_ar">
                            <div class="portfolio-title"> <span> {{ $t('brandName') }}  </span>
                                <h4> {{ project.title_ar }} </h4>
                            </div>
                            <a class="popup-img" :href="project.banner" :alt="project.title_ar"> <i
                                    class="flaticon-plus"></i>
                            </a> 
                            <router-link :to="`/Project/${project.id}`" class="popup-link">  {{ $t('moreDetails') }} </router-link>
                            
                        </div>
                      </slide>
                      <template #addons="{ slidesCount }">
                        <navigation v-if="1<0" />
                        <pagination  v-if="slidesCount > 1" />
                      </template>
                  </carousel>
                </div>
            </div>
        </div>
    </div>
</section>
<!--project end-->
</template>
  
<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import $ from 'jquery';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'magnific-popup/dist/magnific-popup.css';
export default {
  name: "ProjectsSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },

  },
  components: {
    // VueOwlCarousel
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      // modifiedIndexData: {},
      // carousel settings
      settings: {
      itemsToShow: 1,
      snapAlign: 'start',
      dir: 'rtl' ,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 600 and up
      600: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // 800 and up
      800: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 992 and up
      992: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 1024 and up
      1200: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 1792 and up
      1792: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
      // 1920 and up
      1920: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
      // 2560 and up
      2560: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
    };
  },
  computed: {
    projects() {
      return this.indexData.projects;
    },

  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    // this.modifiedIndexData = { ...this.indexData };
    this.initializeMagnificPopup();
  },
  methods: {

    initializeMagnificPopup() {
      $('.popup-gallery').magnificPopup({
        delegate: 'a.popup-img',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function(item) {
            return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
          }
        }
      });

      if ($('.popup-youtube, .popup-vimeo, .popup-gmaps').length > 0) {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
      }
    }
  }
};
</script>

