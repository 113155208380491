<template>
  <!-- what success animatedBackground -->
  <section class="overflow-hidden dark-bg custom-pb-18 " :style="{ backgroundImage: `url('${success.image}')` }" v-if="indexData.success">
      <div class="container">
          <div class="row justify-content-center text-center">
              <div class="col-lg-8 col-md-12">
                  <div class="section-title mb-0">
                      <h6>  {{ $t('successTitele') }} </h6>
                      <h2 class="title">
                        {{ success.title }}
                      </h2>
                      <p class="mb-0 text-white" v-html="success.content">
                       
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "WhatSuccessSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    msg: String
  },
  data() {
    return {
      moveForce: 30,
      rotateForce: 25,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    success() {
      if (!this.indexData) {
        return [];
      }
      return this.indexData.success;
    },
  },

};
</script>
