<template>
  <section class="ourClints grey-bg" v-if="indexData.clients">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <carousel class="py-0" v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="false">
            <slide v-for="client in clients" :key="client.id" class="owl-item mx-auto">
              <a :href="client.url" :alt="client.title_ar" :title="client.title_ar" target="_blank">
                <img class="img-fluid" :src="client.image" :alt="client.title_ar" />
              </a>
            </slide>
            <div class="item mx-auto">
              <router-link to="/contact_us">
                <img
                  id="logo-img"
                  class="img-fluid"
                  v-if="yourplase"
                  :src="yourplase"
                  :alt="Setting.title"
                />
              </router-link>
            </div>
            
            <template #addons="{ slidesCount }">
              <navigation v-if="slidesCount > 1" />
              <pagination v-if="1<0"/>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import yourplase from "@/assets/frontend/plugins/images/client/yourplase.png";
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: "ClientsSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    SettingData: {
      type: Object,
      default: () => ({})
    },
  },
  components: {
    // VueOwlCarousel
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
      dir: 'rtl' ,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 600 and up
      600: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      // 800 and up
      800: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 992 and up
      992: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      // 1024 and up
      1200: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
      // 1792 and up
      1792: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
      // 1920 and up
      1920: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
      // 2560 and up
      2560: {
        itemsToShow: 6,
        snapAlign: 'start',
      },
    },
    yourplase
  }
},
  computed: {
    clients() {
      if (!this.indexData) {
        return [];
      }
      return this.indexData.clients;
    },
    Setting() {
      if (!this.SettingData) {
        return [];
      }
      return this.SettingData.Setting;
    }
  },
  mounted() {
    // No need for the modifiedIndexData assignment, you can directly access the props.

  },

};
</script>
