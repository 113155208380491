<template>
  <div>
    <!--header start-->
    <header id="site-header" class="header">
      <div :class="{ 'fixed-header': isHeaderFixed }" id="header-wrap">
        <div class="container">
          <div class="row">
            <div class="col">
              <nav class="navbar navbar-expand-lg">
                <span class="navbar-brand logo">
                  <router-link to="/">
                    <img
                      id="logo-img"
                      class="img-fluid"
                      v-if="Setting"
                      :src="Setting.style_logo"
                      :alt="Setting.title"
                    />
                  </router-link>
                </span>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav mx-auto position-relative">
                    <!-- Home -->
                    <li class="nav-item">
                      <router-link to="/" class="nav-link active">{{ $t('home') }}</router-link>
                    </li>

                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >{{ $t('about_ragmyat') }}</a>
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          <router-link to="/about">
                            <span>{{ $t("about_us") }}</span>
                          </router-link>
                          <router-link to="/Why_us">
                            <span>{{ $t("why_us") }}</span>
                          </router-link>
                          <router-link to="/Methodology">
                            <span>{{ $t("methodology") }}</span>
                          </router-link>
                          <router-link to="/Distinction">
                            <span>{{ $t("distinction") }}</span>
                          </router-link>
                          <router-link to="/Skills">
                            <span>{{ $t("skills") }}</span>
                          </router-link>

                        </ul>
                      </div>
                    </li>

                    <li class="nav-item dropdown" v-if="menuServices">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >{{ $t('services') }}</a>
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          <router-link v-for="mserv in menuServices.slice(0, 5)" :key="mserv.id" :to="'/Servese/' + mserv.id">
                            <span>{{ mserv.title_ar }}</span>
                          </router-link>
                          <router-link to="/Services">
                            <span>{{ $t("view_all") }}</span>
                          </router-link>
                        </ul>
                      </div>
                    </li>

                    <li class="nav-item dropdown" v-if="menuProjects">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >{{ $t('projects') }}</a>
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          
                          <router-link v-for="mpro in menuProjects.slice(0, 5)" :key="mpro.id" :to="'/Project/' + mpro.id">
                            <span>{{ mpro.title_ar }}</span>
                          </router-link>
                          <router-link to="/Projects">
                            <span>{{ $t("view_all") }}</span>
                          </router-link>
                          
                        </ul>
                      </div>
                    </li>

                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >{{ $t('our_new') }}</a>
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          <router-link to="/Raqeeb">
                            <span>{{ $t("raqeeb") }}</span>
                          </router-link>
                          <router-link to="/QFatora">
                            <span>{{ $t("qFatora") }}</span>
                          </router-link>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >{{ $t('more') }}</a>
                      <div class="dropdown-menu">
                        <ul class="list-unstyled">
                          <router-link to="/DigitalTransformation">
                            <span>{{ $t("digitalTransformation") }}</span>
                          </router-link>
                          <router-link to="/ApplicationDevelopment">
                            <span>{{ $t("applicationDevelopment") }}</span>
                          </router-link>
                          <router-link to="/contact_us">
                            <span>{{ $t("contact_us") }}</span>
                          </router-link>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="right-nav align-items-center d-flex justify-content-end" v-if="Setting">
                  <div class="top-social">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a :href="Setting.facebook">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a :href="Setting.twitter">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a :href="'https://wa.me/' + Setting.whatsapp">
                          <i class="fab fa-whatsapp"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a :href="Setting.googleplus">
                          <i class="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a :href="Setting.instagram">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a :href="Setting.linkedin">
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <a href="#" class="ht-nav-toggle" @click="toggleMenu">
                    <span></span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <nav id="ht-main-nav">
      <a href="#" class="ht-nav-toggle active" @click="toggleMenu">
        <span></span>
      </a>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <img
              class="img-fluid side-logo mb-3"
              v-if="Setting"
              :src="Setting.style_logo"
              :alt="Setting.title"
            />
            <p class="mb-5 text-justify">{{ resultsubMenu }}</p>
            <div class="form-info" v-if="Setting">
              <h4 class="title">{{ $t('contact_us') }}</h4>
              <ul class="contact-info list-unstyled mt-4">
                <li class="mb-4">
                  <i class="flaticon-location"></i>
                  <span>{{ $t('address') }} :</span>
                  <p>{{ Setting.address_ar }}</p>
                </li>
                <li class="mb-4">
                  <i class="flaticon-call"></i>
                  <span>{{ $t('callPhone') }} :</span>
                  <a :href="'tel: ' + Setting.phone">
                    <bdo dir="ltr">{{ Setting.phone }}</bdo>
                  </a>
                </li>
                <li>
                  <i class="flaticon-email"></i>
                  <span>{{ $t('email') }} :</span>
                  <a :href="'mailto:' + Setting.email">{{ Setting.email }}</a>
                </li>
              </ul>
            </div>
            <div class="social-icons social-colored mt-5" v-if="Setting">
              <ul class="list-inline">
                <li class="mb-2 social-facebook">
                  <a :href="Setting.facebook">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li class="mb-2 social-twitter">
                  <a :href="Setting.twitter">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li class="mb-2 social-linkedin">
                  <a :href="'https://wa.me/' + Setting.whatsapp">
                    <i class="fab fa-whatsapp"></i>
                  </a>
                </li>

                <li class="mb-2 social-gplus">
                  <a :href="Setting.googleplus">
                    <i class="fab fa-google-plus-g"></i>
                  </a>
                </li>
                <li class="mb-2 social-instagram">
                  <a :href="Setting.instagram">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li class="mb-2 social-linkedin">
                  <a :href="Setting.linkedin">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!--header end-->
  </div>
</template>
<script>

export default {
  name: "MenueSection",
  props: {
    SettingData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modifiedIndexData: {},
      isHeaderFixed: false,
      // SettingData: {},

    };
  },
  created() {
      window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    menuServices() {
      return this.SettingData?.menuServices;
    },
    menuProjects() {
      return this.SettingData.menuProjects;
    },
    Setting() {
      return this.SettingData.Setting;
    },
    aboutinfoMenu() {
      return this.SettingData.aboutinfoMenu;
    },
    resultsubMenu() {
      return this.SettingData.resultsubMenu;
    }
  },
  mounted() {
    // Modify the SettingData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.SettingData };
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset >= 100) {
        this.isHeaderFixed = true;
      } else {
        this.isHeaderFixed = false;
      }
    },
    toggleMenu() {
      if (document.body.classList.contains('menu-show')) {
        document.body.classList.remove('menu-show');
        document.querySelector('#ht-main-nav > .ht-nav-toggle').classList.remove('show');
      } else {
        document.body.classList.add('menu-show');
        setTimeout(() => {
          document.querySelector('#ht-main-nav > .ht-nav-toggle').classList.add('show');
        }, 900);
      }
    }
  }
};
</script>
