<template>
  <div class="home">
    <HeroSection v-if="indexData" :indexData="indexData"  />
    <IndexPeage v-if="indexData" :indexData="indexData" msg="Welcome to Profile Vue.js App" />
    <ServesesSection v-if="indexData" :indexData="indexData" />
    <FeaturesSection v-if="indexData && SettingData" :SettingData="SettingData" :indexData="indexData"  />
    <ProjectsSection v-if="indexData" :indexData="indexData"  />
    <WhatSuccessSection v-if="indexData" :indexData="indexData"  />
    <ClientsSection v-if="indexData" :indexData="indexData"  />
    <ContactSection v-if="indexData" :indexData="indexData"  />
    
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/HeroSection.vue";
import IndexPeage from "@/components/IndexPeage.vue";
import ServesesSection from "@/components/ServesesSection.vue";
import FeaturesSection from "@/components/FeaturesSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import ClientsSection from "@/components/ClientsSection.vue";
import ProjectsSection from "@/components/ProjectsSection.vue";
import WhatSuccessSection from "@/components/WhatSuccessSection.vue";

// import axios from "axios";
export default {
  name: "HomeView",
  props: {
    SettingData: {
      type: Object,
      default: () => ({})
    }
  },
  metaInfo() {
    return {
      title: this.SettingData && this.SettingData.title ? this.SettingData.title : this.title,
      meta: [
        {
          name: 'title',
          content: this.SettingData && this.SettingData.title ? this.SettingData.title : this.title,
        },
        {
          name: 'description',
          content: this.SettingData && this.SettingData.description ? this.SettingData.title : this.title,
        },
        // Add more meta tags as needed
      ],
    };
  },
  data() {
    return {
      msg: "Welcome to Profile Vue.js App",
      indexData: undefined
    };
  },
  created() {
    
    this.$axios.get("/home")
      .then(response => {
        this.indexData = response.data;
        // console(JSON.parse(JSON.stringify(this.indexData.aboutinfo)));
      })
      .catch(error => {
        console.error(error);
      });
  },
  components: {
    HeroSection,
    IndexPeage,
    ServesesSection,
    FeaturesSection,
    ContactSection,
    ProjectsSection,
    WhatSuccessSection,
    ClientsSection,
  }
};
</script>
