<template>
  <section
    class="overflow-hidden dark-bg custom-pb-18"
    :style="{ backgroundImage: `url('${rangerVue}')` }"
  v-if="Features && Setting">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8 col-md-12">
          <div class="section-title mb-0">
            <h6>{{ $t('rowadAltahol') }}</h6>
            <h2 class="title" >{{ Setting.title }}</h2>
          </div>
        </div>
      </div>
      <div class="row mx-auto text-center services">
        <div
          v-for="(feature, index) in Features"
          :key="index"
          class="col-lg-3 col-md-6 align-items-stretch aos-init aos-animate mx-auto"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-box">
            <div class="icon">
              <img
                class="icon_plue"
                :src="feature.image"
                :alt="feature.title"
              />
            </div>

            <h4>{{ feature.title }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
    
  <script>
import rangerVue from "@/assets/frontend/plugins/images/ranger5.png";

export default {
  name: "FeaturesSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    SettingData: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      modifiedIndexData: {},
      rangerVue
    };
  },
  computed: {
    Features() {
      return this.indexData.features;
    },
    Setting() {
      if (!this.SettingData || !this.SettingData.Setting) {
        return [];
      }
      return this.SettingData.Setting;
    }
  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.indexData };
  }
};
</script>
  
  