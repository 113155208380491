{
  "topicNew" : "إضافة ",
  "topicEdit" : "تعديل ",
  "topicName" : " العنوان",
  "topicPhoto" : "صورة",
  "topicSection" : "القسم",
  "topicSelectSection" : "حدد القسم",
  "topicDate" : "التاريخ",
  "topicAudio" : "الملف الصوتي",
  "topicVideo" : "ملف الفيديو ",
  "topicAttach" : "ملف مرفق",
  "topicDeletedSection" : "غير تابع لأي قسم",
  "topicTabSection" : "تفاصيل القسم",
  "topicTabDetails" : "تفاصيل الموضوع",
  "topicSEOTitle" : "عنوان الصفحة",
  "topicSEODesc" : "الوصف",
  "topicSEOKeywords" : "الكلمات الدلالية",
  "topicAdditionalPhotos" : "صور إضافية",
  "topicGoogleMaps" : "خرائط جوجل",
  "topicDropFiles" : "ادرج الصور هنا أو انقر للتحميل",
  "topicDropFiles2" : "يمكنك تحميل العديد من الصور في نفس الوقت",
  "topicCommentName" : "الإسم",
  "topicCommentEmail" : "البريد الإلكتروني",
  "topicComment" : "التعليق",
  "topicNewComment" : "تعليق جديد",
  "topicNewMap" : "إضافة خريطة",
  "topicMapTitle" : "العنوان",
  "topicMapDetails" : "التفاصيل",
  "topicMapLocation" : "الموقع",
  "topicMapIcon" : "أيقونة",
  "topicMapClick" : "إضغط علي المكان الذي تريد الإضافة فية",
  "topicMapORClick" : "أو يمكنك الإضافة يدويا من هنا",
  "allContacts" : "جميع المسجلين",
  "waitActivation" : "بإنتظار التفعيل",
  "blockedContacts" : "المسجلين المحظورين",
  "newGroup" : "مجموعة جديدة",
  "newContacts" : "إضافة حساب جديد",
  "editContacts" : "تعديل الحساب",
  "deleteContacts" : "حذف الحساب",
  "searchAllContacts" : "بحث في جميع المسجلين",
  "firstName" : "الإسم الأول",
  "lastName" : "الإسم الأخير",
  "companyName" : "اسم الشركة",
  "city" : "المدينه",
  "notes" : "ملاحظات",
  "group" : "التصنيف",
  "sendEmail" : "ارسال بريد",
  "callNow" : "اتصل الآن",
  "selectFile" : "حدد الملف",
  "labels" : "أقسام أخرى",
  "inbox" : "البريد الوارد",
  "sent" : "المرسل",
  "draft" : "المسودة",
  "compose" : "رسالة جديدة",
  "makeAsRead" : "اجعلها مقروءة",
  "makeAsUnread" : "اجعلها غير مقروءة",
  "moveTo" : "نقل إلى ",
  "replay" : "رد على الرسالة",
  "forward" : "إعادة توجيه",
  "sendTo" : "إلى",
  "sendFrom" : "من",
  "sendCc" : "Cc",
  "sendBcc" : "Bcc",
  "sendTitle" : "عنوان الرسالة",
  "SaveToDraft" : "حفظ بالمسودة",
  "send" : "إرسال",
  "writeYourMessage" : "اكتب رسالتك هنا",
  "PleaseWriteYourMessage" : "لوسمحت يرجى كتابة رسالتك",
  "print" : "طباعة",
  "AttachFiles" : "ملفات مرفقة",
  "newEvent" : "اضافة حدث / ملاحظة",
  "eventTotal" : "الاجمالي",
  "eventTitle" : "الحدث / الملاحظة ",
  "eventStart" : "بداية الحدث",
  "eventEnd" : "نهاية الحدث",
  "eventStart2" : "تاريخ البداية",
  "eventEnd2" : "تاريخ النهاية",
  "eventDetails" : "التفاصيل",
  "eventNote" : "ملاحظة",
  "eventMeeting" : "موعد",
  "eventEvent" : "حدث",
  "eventTask" : "مهمة",
  "eventType" : "النوع",
  "eventAt" : "في",
  "eventToday" : "اليوم",
  "eventDay" : "عرض يومي",
  "eventWeek" : "أسبوعي",
  "eventMonth" : "شهري",
  "eventDelete" : "حذف هذا الحدث / الملاحظة؟",
  "eventClear" : "حذف جميع الملاحظات والأحداث",
  "diagram" : "رسم توضيحي",
  "barDiagram" : "عرض البيانات في شكل رسم بياني",
  "visitors" : "عدد الزوار",
  "ip" : "IP",
  "pageViews" : "عدد الصفحات",
  "today" : "اليوم",
  "yesterday" : "أمس",
  "last7Days" : "أخر 7 أيام",
  "last30Days" : "أخر 30 يوم",
  "thisMonth" : "الشهر الحالي",
  "lastMonth" : "الشهر الماضي",
  "applyFrom" : "من",
  "applyTo" : "إلى",
  "customRange" : "فترة مخصصة",
  "activity" : "النشاط",
  "activitiesHistory" : "سجل الأنشطة",
  "newUser" : "إضافة مستخدم جديد",
  "editUser" : "تعديل مستخدم",
  "fullName" : "الإسم بالكامل",
  "permission" : "الصلاحية",
  "permission1" : "مسؤول الموقع",
  "permission2" : "مدير الموقع ( جميع الصلاحيات )",
  "permission3" : "مستخدم عادي ( بدون حذف )",
  "permission4" : "مشرف/مراجع ( عرض فقط )",
  "personalPhoto" : "صورة شخصية",
  "loginEmail" : "البريد الإلكتروني",
  "loginPassword" : "كلمة المرور",
  "users" : "المستخدمين",
  "permissions" : "الصلاحيات",
  "newPermissions" : "إضافة صلاحية جديدة",
  "title" : "العنوان",
  "dataManagements" : "إدارة المحتوى",
  "dataManagements1" : "المحتوى الخاص به فقط (للعضويات الشخصية فقط)",
  "dataManagements2" : "كافة المحتويات من جميع المستخدمين (لعضويات الإدارة العامة)",
  "activeApps" : "التطبيقات المفعلة",
  "activeSiteSections" : "أقسام الموقع المفعلة",
  "addPermission" : "صلاحية الإضافة",
  "editPermission" : "صلاحية التعديل",
  "deletePermission" : "صلاحية الحذف",
  "viewOnly" : "مشاهدة فقط",
  "editPermissions" : "تعديل الصلاحيات",
  "perAdd" : "الإضافة",
  "perEdit" : "التعديل",
  "perDelete" : "الحذف",
  "selectPermissionsType" : "حدد نوع الصلاحية",
  "newLink" : "اضافة رابط جديد",
  "newMenu" : "اضافة قائمة جديدة",
  "menuTitle" : "اسم القائمة",
  "editSection" : "تعديل رابط",
  "linkType" : "نوع الرابط",
  "linkType1" : "عنوان رئيسي",
  "linkType2" : "رابط مباشر",
  "linkType3" : "قسم رئيسي",
  "linkType4" : "قائمة منسدلة لقسم",
  "linkURL" : "الرابط",
  "linkSection" : "قسم الرابط",
  "sectionTitle" : "عنوان القسم / الرابط",
  "fatherSection" : "القسم الرئيسي",
  "latestMessages" : "أحدث الرسائل",
  "notesEvents" : "الملاحظات والأحداث",
  "latestContacts" : "جديد جهات الإتصال",
  "more" : "المزيد",
  "viewMore" : "مشاهدة المزيد",
  "addNew" : "إضافة",
  "reports" : "التقارير",
  "reportsDetails" : "يمكنك مشاهدة المزيد من التقارير",
  "hi" : "أهلاً",
  "welcomeBack" : "مرحباً بعودتك",
  "lastFor7Days" : "الزوار لأخر ٧ أيام",
  "todayByCountry" : "زوار اليوم حسب الدولة",
  "browsers" : "متصفح الإنترنت",
  "browsersCalculated" : "نسبة المتصفحات حسب أخر ٧ أيام",
  "visitorsRate" : "معدل الزوار",
  "visitorsRateToday" : "معدل الزوار على مدار اليوم الحالي",
  "sections" : "الاقسام",
  "resultsFoundFor" : "نتائج البحث عن",
  "connectEmailToConnect" : "ربط مع البريد الإلكتروني",
  "connectEmail" : "البريد الإلكتروني",
  "connectPassword" : "كلمة المرور",
  "openWebmail" : "افتح البريد الإلكتروني",
  "themeSwitcher" : "التصميم واللغة",
  "foldedAside" : "تصغير القائمة الجانة",
  "boxedLayout" : "تصغير عرض النافذة",
  "themes" : "التصاميم",
  "themes1" : "فاتح",
  "themes2" : "رمادي",
  "themes3" : "غامق",
  "themes4" : "أسود",
  "language" : "اللغة",
  "change" : "حفظ التغييرات",
  "sitePreview" : " معاينة الموقع",
  "oops" : "عفوا",
  "export" : "تصدير",
  "close" : "اغلاق",
  "columns" : "الاعمده",
  "noPermission" : "نأسف ! ليس لديك الصلاحية لزيارة هذه الصفحة",
  "notFound" : "نأسف! الصفحة التي تبحث عنها غير موجودة",
  "forgotPassword" : "نسيت كلمة المرور؟",
  "signIn" : "تسجيل الدخول",
  "keepMeSignedIn" : "حفظ بيانات تسجيل الدخول",
  "signedInToControl" : "تسجيل الدخول إلى لوحة التحكم",
  "control" : "لوحة التحكم",
  "resetPassword" : "إستعادة كلمة المرور",
  "confirmPassword" : "تاكيد كلمة المرور",
  "newPassword" : "كلمة مرور جديدة",
  "yourEmail" : "بريدك الإلكتروني",
  "sendPasswordResetLink" : "ارسال رابط استعادة كلمة المرور",
  "returnTo" : "عودة للخلف",
  "enterYourEmail" : "برجاء كتابة بريدك الالكتروني ",
  "expireDateField" : "حقل تاريخ الإنتهاء",
  "expireDate" : "تاريخ الإنتهاء",
  "expired" : "منتهي",
  "additionalFiles" : "ملفات إضافية",
  "relatedTopics" : "مواضيع ذات صلة",
  "relatedTopicsAdd" : "إضافة مواضيع ذات صلة",
  "relatedTopicsSelect" : "حدد القسم أولا لإختيار المواضيع",
  "optionalFields" : "الحقول الإختيارية",
  "additionalTabs" : "تبويبات إضافية",
  "active_disable" : "تفعيل/إيقاف",
  "friendlyURL" : "رابط الصفحة",
  "seoTabSettings" : "إدارة عنوان الصفحة، وصف ميتا، الكلمات الدلالية ورابط الصفحة. هذا يساعدك على إدارة كيفية ظهور هذا الموضوع على محركات البحث.",
  "friendlyURLinks" : "طريقة روابط الواجهة",
  "friendlyURLinks1" : "استخدام الروابط الإفتراضية",
  "friendlyURLinks2" : "استخدام الروابط صديقة محركات البحث ",
  "registrationSettings" : "إعدادات التسجيل",
  "allowRegister" : "تفعيل إمكانية التسجيل في لوحة التحكم",
  "permissionForNewUsers" : "مجموعة صلاحيات المسجلين الجدد",
  "createNewAccount" : "إنشاء حساب جديد",
  "APIStatus" : "تفعيل RESTful API",
  "APIKey" : "API مفتاح",
  "APIKeyGenerate" : "إنشاء مفتاح جديد",
  "APIKeyConfirm" : "هل تريد بالفعل إنشاء مفتاح جديد؟",
  "homeRow_3" : "محتويات الرئيسة الصف الثالث",
  "partners" : "عملائنا",
  "iframebtn" : "إختيار من مدير الملفات",
  "FileManager" : "مدير الملفات",
  "CatBackendMenu" : "تصنيف أقسام الموقع",
  "ViewPermission" : "صلاحية الوصول",
  "darkblue" : "ازرق غامق",
  "DeveloperSection" : "خاص بالمطور",
  "BannarSection" : "بنرات ثابتة",
  "SectionsInfoList" : "معلومات عن المتجر",
  "Counters" : "الاحصائيات",
  "jobs" : "وظائف",
  "RequestProject" : "طلبات خدمات رقمية",
  "toTop" : "إلى الأعلى",
  "AllRightsReserved" : "جميع الحقوق محفوظة لدى ",
  "moreDetails" : "مزيد من التفاصيل",
  "brandName" : " الاسم التجاري",
  "readMore" : " إقرأ المزيد ",
  "downloadAttach" : "تحمل المرفقات",
  "contactDetails" : "بيانات الإتصال",
  "callUs" : "اتصل الآن",
  "callFax" : "الفاكس",
  "callMobile" : "الجوال",
  "callPhone" : "الهاتف",
  "callTimes" : "أوقات العمل",
  "address" : "العنوان",
  "name" : "الأسم",
  "phone" : "الموبايل",
  "latestNews" : "جديد الأخبار",
  "ourGallery" : "معرض الصور",
  "subscribeToOurNewsletter" : "اشترك في  النشرة البريدية",
  "subscribeToOurNewsletterDone" : "لقد اشتركت بنجاح",
  "subscribeToOurNewsletterError" : "أنت بالفعل مشترك معنا",
  "subscribe" : "اشترك",
  "yourName" : "إسمك",
  "enterYourName" : "برجاء كتابة إسمك",
  "enterYourPhone" : "برجاء كتابة رقم هاتفك ",
  "enterYourSubject" : "برجاء كتابة عنوان لرسالتك",
  "enterYourMessage" : "برجاء كتابة تفاصيل الرسالة",
  "youMessageSent" : "تم إرسال رسالتكم بنجاح، وسنقوم بالتواصل معكم في أقرب وقت ممكن. نشكركم لمراسلتنا!",
  "youMessageNotSent" : "خطأ: برجاء إعادة المحاولة",
  "subject" : "عنوان الرسالة",
  "message" : "تفاصيل الرسالة",
  "sendMessage" : "ارسال الرسالة",
  "workingHours" : "أوقات العمل",
  "categories" : "التصنيفات",
  "mostViewed" : "الأكثر مشاهدة",
  "share" : "نشر",
  "locationMap" : "خريطة الموقع",
  "comment" : "التعليق",
  "enterYourComment" : "برجاء كتابة التعليق",
  "sendComment" : "ارسال التعليق",
  "youCommentSent" : "تم إضافة التعليق بنجاح",
  "newComment" : "إضافة تعليق جديد",
  "refresh" : "تحديث",
  "getInTouch" : "يمكنك مراسلتنا عن طريق تعبئة النموذج التالي",
  "homeContents1Title" : "أحدث المقالات",
  "homeContents1desc" : "احدث المقالات الجديدة والمميزة التي أضيفت في المدونة",
  "homeContents2Title" : "أعمالنا الحالية",
  "homeContents2desc" : "مجموعة من أحدث أعمالنا، يمكنك تصفح المزيد",
  "orderForm" : "نموذج الطلب",
  "quantity" : "الكميه",
  "yourQuantity" : "برجاء إضافة الكمية المطلوبة",
  "sendOrder" : "ارسال الطلب",
  "youOrderSent" : "تم إرسال الطلب بنجاح، وسنقوم بالتواصل معكم في أقرب وقت ممكن.",
  "partnersMsg" : "عملاء وشركات متميزة طويلة الأمد مع رواد الشركات المحلية والعالمية",
  "OurService" : "خدماتنا",
  "RequestNow" : "اطلب الان",
  "favorite" : "المفضلة",
    "cards_plural" : "ارسال اوامر شراء",
    "cards_desc" : "ادارة اوامر الشراء",
    "cards_edit" : "Edit Cards",
    "cards_table" : "النشطه",
    "cards_create" : "المرسلة",
    "cards_stock_id" : "الصنف",
    "cards_stock_id_help" : "Insert Stock Id",
    "cards_stock_id_placeholder" : "Insert Stock Id",
    "cards_user_id" : "User Id",
    "cards_user_id_help" : "Insert User Id",
    "cards_user_id_placeholder" : "Insert User Id",
    "cards_quantity" : "Quantity",
    "cards_quantity_help" : "Insert Quantity",
    "cards_quantity_placeholder" : "Insert Quantity",
    "users_supplier" : "مستخدمين الفروع",
    "users_supplier_plural" : "مستخدمين الفروع",
    "users_supplier_desc" : "إدارة مستخدمين الفروع",
    "users_supplier_edit" : "تعديل مستخدمين الفروع",
    "users_supplier_table" : "قائمة مستخدمين الفروع",
    "users_supplier_create" : "انشاء مستخدمين الفروع",
    "users_supplier_id" : "Id",
    "users_supplier_id_help" : "Insert Id",
    "users_supplier_id_placeholder" : "Insert Id",
    "users_supplier_name" : "Name",
    "users_supplier_name_help" : "Insert Name",
    "users_supplier_name_placeholder" : "Insert Name",
    "offer_price" : "سعر العرض",
    "minimum_offers" : "الحد الأدنى للعرض",
    "branches_contacts" : "اتصالات الفروع",
    "branches_contacts_plural" : "اتصالات الفروع",
    "branches_contacts_desc" : "إدارة اتصالات الفروع",
    "branches_contacts_edit" : "تعديل اتصالات الفروع",
    "branches_contacts_table" : "قائمة اتصالات الفروع",
    "branches_contacts_create" : "انشاء اتصالات الفروع",
    "branches_contacts_branches_id" : "فرع",
    "branches_contacts_branches_id_help" : "اضافة فرع",
    "branches_contacts_branches_id_placeholder" : "اضافة فرع",
    "branches_contacts_name" : "الأسم",
    "branches_contacts_name_help" : "اضافة اسم",
    "branches_contacts_name_placeholder" : "اضافة اسم",
    "branches_contacts_email" : "Email",
    "branches_contacts_email_help" : "الايميل",
    "branches_contacts_email_placeholder" : "الايميل",
    "branches_contacts_phone" : "رقم الجوال",
    "branches_contacts_phone_help" : "رقم الجوال",
    "branches_contacts_phone_placeholder" : "اضافة رقم الجوال",
    "choose_branches" : "اختر فروع",
    "name_ar_required" : "الاسم مطلوب",
    "name_en_required" : "الاسم الاجنبي مطلوب",
    "name_ar_min" : "يجب ان يكون طول الاسم اكبر من حرفين",
    "name_ar_unique" : "الاسم موجود من قبل",
    "id_country_required" : "اسم الدوله مطلوب",
    "id_area_required" : "اسم المنطقه مطلوب",
    "item_required" : "هذا الحقل اجباري !",
    "state_key" : "مفتاح الدولة",
    "insert_state_key" : "ادخل مفتاح الدولة",
    "group_packages" : "Group Packages",
    "group_packages_plural" : "Group Packages",
    "group_packages_name_ar" : "Name Ar",
    "group_packages_name_en" : "Name En",
    "group_packages_note" : "Note",
    "country_choose" : "اختيار الدوله",
    "area_choose" : "اختيار المنطقه",
    "city_choose" : "اختيار المدينه",
    "street_choose" : "اختيار الحي",
    "districts_Let" : "خط العرض",
    "districts_Lng" : "خط الطول",
    "Formatted_Address" : "تنسيق العنوان",
    "branches_all" : "كل الفروع",
    "select_branch" : "حدد الفرع",
    "partnumber" : "رقم القطعه",
    "retailPrice" : " سعر التجزئه",
    "wholesalesPrice" : " سعر الجمله",
    "more_filters" : "مزيد من الفلتره",
    "minimum" : "الحد الادنى",
    "maximum" : "الحد الاعلى",
    "update_in" : " تم التحديث ",
    "insert_partnumber" : " ادخل رقم القطعه",
    "insert_retailPrice" : "ادخل سعر التجزئه ",
    "insert_wholesalesPrice" : " ادخل سعر الجمله",
    "insert_quantity" : " ادخل الكميه",
    "allcountry" : " كل الدول",
    "allarea" : " كل المناطق",
    "allcity" : " كل المدن",
    "allstreet" : " كل الاحياء ",
    "offers" : "العروض",
    "offer_management" : "ادارة العروض",
    "traders_offers" : "عروض التجار ",
    "parts_catalog" : " كتالوج القطع  ",
    "subscriptions" : " الاشتراكات",
    "connect_channel_b_stores" : "  قنوات التواصل بين المتاجر",
    "page" : "الصفحة",
    "page_plural" : "الصفحات",
    "page_name" : "كود الصفحة",
    "page_title_ar" : "اسم الصفحة عربي",
    "page_title_en" : "اسم الصفحة انجليزي",
    "page_status" : "حالة الصفحة",
    "routelist" : "قائمة المسارات",
    "page_type" : "النوع",
    "multiple_route" : "لها اكثر من مسار",
    "one_route" : "مسار واحد فقط",
    "add_privliges_page" : "اضافة صلاحيات الصفحات",
    "single" : "عازب",
    "married" : "متزوج",
    "widower" : "ارمل",
    "engaged" : "خاطب",
    "username_is_email" : "اسم المستخدم هو البريد الإلكتروني",
    "users_roles" : "مجموعات المستخدمين",
    "logowhite" : "الشعار ابيض",
    "time_zone" : "المنطقة الزمنية",
    "time_zone_plural" : "المناطق الزمنية",
    "time_zone_zone" : "المنطقة",
    "time_zone_gmt" : "بتوقيت غرينيتش",
    "arabicBox" : "<small>  العربية  </small>",
    "englishBox" : "<small>  English  </small>",
    "addaddress" : "اضافة العنوان",
    "stock_search" : "البحث",
    "stock_create_MoreThanRecord" : "اضافة اكثر من قطعة",
    "purchaseorders" : "ادارة اوامر الشراء المستلمة",
    "cards_beenapproved" : "تمت الموافقه",
    "cards_disapproval" : "المرفوضه",
    "name_en_min" : "يجب ان يكون طول الاسم الاجنبي اكبر من حرفين",
    "name_ar_max" : "يجب ان يكون طول الاسم اقل من 255 حرف",
    "name_en_max" : "يجب ان يكون طول الاسم الاجنبي اقل من 255 حرف",
    "emailunique" : "الايميل موجود من قبل",
    "Add" : "عملية الاضافة",
    "Edit" : "عملية التعديل",
    "recovery" : "عملية الاستعاده",
    "t_users" : "جدول المستخدمين",
    "t_stock" : "جدول المخزون",
    "t_brand" : "جدول العلامات التجاريه",
    "t_countries" : "جدول الدول",
    "t_roles" : "مجموعة المستخدمين",
    "no_operations" : "لا توجد عمليات على السجل",
    "all_operations" : "جميع العمليات على السجل",
    "Record_details" : "تفاصيل السجل",
    "minimum_number" : "الحد الأدنى لعدد الحروف هو ",
    "image_required" : "الصوره مطلوب ",
    "record_number" : "رقم السجل",
    "image" : "الصوره",
    "selectimage" : "اختر الصوره",
    "operation_type" : "نوع العمليه",
    "date_and_time" : "التاريخ والوقت",
    "note" : "الملاحظه",
    "inactive" : "غير فعال",
    "areyousure" : "هل انت متاكد من عملية الحذف",
    "last_update" : "اخر عملية تحديث",
    "name_screen" : "اسم اشاشه",
    "restaurant_mobile_help" : "اضافة الجوال",
    "mobile" : "رقم  الجوال ",
    "coderole" : "كود المجموعة ",
    "insertcoderole" : "ادخل كود المجموعة",
    "description" : "الوصف",
    "insertdescription" : "ادخل الوصف",
    "actions" : "الأجراءات",
    "deleted_successfully" : "تم حذف بنجاح",
    "restaurant_latitude" : "خط العرض",
    "restaurant_latitude_help" : "اضافة خط العرض",
    "restaurant_latitude_placeholder" : "اضافة خط العرض",
    "restaurant_longitude" : "خط الطول",
    "restaurant_longitude_help" : "اضافة خط الطول",
    "restaurant_longitude_placeholder" : "اضافة خط الطول",
    "menu_item_title" : "العنوان",
    "menu_item_title_help" : "اضافة العنوان",
    "menu_item_title_placeholder" : "اضافة العنوان",
    "dealing_with_piece" : "التعامل مع القطعه",
    "dealing_with_part_number" : "التعامل مع القطعه رقم",
    "purchase_order_approved" : " الموافقه على أمر الشراء",
    "Purchase_order_rejected" : "رفض أمر الشراء",
    "send_purchase_Order" : "ارسال أمر الشراء",
    "delete_Order" : "حذف القطعه من امر الشراء",
    "please_enter_note" : "الرجاء ادخال الكمية و الملاحظة ",
    "change_password" : "تغيير كلمة المرور",
    "edit_profile" : "تعديل البروفايل",
    "design_by" : "تصميم وبرمجة",
    "ragmyat" : " رقميات التحول لتقنية المعلومات",
    "favorite_user_id" : "اسم المستخدم",
    "statistics" : "الاحصائيات",
    "printed" : " طباعه بواسطه ",
    "date" : "تاريخ الطباعه",
    "Invoice_No" : "رقم الفاتوره",
    "from_date" : "من تاريخ",
    "to_date" : "الى تاريخ",
    "newNotification" : "اشعارات جديده",
    "MarkallRead" : "تحديد الكل كمقروء",
    "Read_all_notifications" : "اقرأ جميع الإخطارات",
    "nounreadnotification" : "ليس لديك أي اشعارات غير مقروءة.",
    "typing" : "جاري الكتابة ...",
    "package_plural" : "الباقات",
    "package_name_ar" : "الاسم عربي",
    "package_name_en" : "الاسم انجليزي",
    "package_uuid" : "Uuid",
    "package_status" : "الحالة",
    "package_package_period_id" : "الفترة",
    "Package" : "الباقة",
    "Packages" : "الباقات",
    "package_period" : "فترات الاشتراك",
    "package_period_plural" : "فترات الاشتراك",
    "package_period_name_ar" : "الاسم  عربي",
    "package_period_name_en" : "الاسم انجليزي",
    "package_period_price" : "سعر الباقة",
    "package_period_total_days" : "اجمالي الايام",
    "package_services" : "خدمات الباقات",
    "package_services_plural" : "خدمات الباقات ",
    "package_services_name" : "الاسم ",
    "package_services_uuid" : "الاسم الفريد",
    "package_services_status" : "الحالة",
    "package_services_parent_id" : "اسم الاب",
    "package_services_has_parent" : "لديه اب",
    "package_services_placeholder" : "اختار",
    "has_parent" : "لدية اب",
    "not_has_parent" : "ليس لدية اب",
    "package_choose_parent" : "يجب اختيار الاب",
    "packages_user" : "مشتركين الباقات",
    "packages_user_plural" : "مشتركين الباقات",
    "packages_user_user_id" : "المشترك",
    "packages_user_package_group_id" : "اسم الباقة ",
    "packages_user_start_join" : "Start Join",
    "packages_user_end_join" : "انتهاء الاشتراك",
    "packages_user_cost" : "التكلفة",
    "packages_user_note" : "ملاحظات",
    "InitializeApplication" : "تهيئة التطبيق",
    "package_group" : "Package Group",
    "package_group_plural" : "Package Groups",
    "package_group_name_ar" : "Name Ar",
    "package_group_name_en" : "Name En",
    "package_group_uuid" : "Uuid",
    "package_group_status" : "الحالة",
    "package_group_package_period_id" : "Package Period Id",
    "package" : "الباقة",
    "package_period_id" : "الفترة",
    "package_monthly_const" : "التكلفة الشهرية",
    "packages_user_package_id" : "الباقة",
    "packages_user_status" : "الحالة",
    "package_counter" : "Package Counter",
    "package_counter_plural" : "Package Counters",
    "package_counter_package_id" : "Package Id",
    "package_counter_package_service_id" : "Package Service Id",
    "package_counter_value" : "Value",
    "package_counter_status" : "Status",
    "package_period_status" : "الحالة",
    "package_service" : "خدمات الباقات",
    "package_service_plural" : "خدمات الباقات",
    "package_service_name" : "اسم الخدمة",
    "package_service_uuid" : "Uuid",
    "package_service_status" : "Status",
    "package_service_parent_id" : "الاب",
    "package_service_has_parent" : "يوجد لها اب",
    "package_six_month_cost" : "تكلفة 6 اشهر",
    "package_yearly_cost" : "Yearly Cost",
    "advertisement" : "اعلان",
    "advertisement_plural" : "الإعلانات",
    "advertisement_title_ar" : "العنوان عربي",
    "advertisement_title_en" : "العنوان انجليزي",
    "advertisement_abstract_ar" : "الوصف عربي",
    "advertisement_abstract_en" : "الوصف انجليزي",
    "advertisement_image" : "الصورة",
    "advertisement_status" : "الحالة",
    "advertisement_sort_id" : "الترتيب",
    "topic" : "Topic",
    "topic_plural" : "Topics",
    "topic_title_ar" : "Title Ar",
    "topic_title_en" : "Title En",
    "topic_details_ar" : "Details Ar",
    "topic_details_en" : "Details En",
    "topic_image" : "Image",
    "topic_type" : "Type",
    "topic_icon" : "Icon",
    "topic_status" : "Status",
    "topic_url" : "Url",
    "banner" : "الغلاف",
    "banner_plural" : "الصور المتحركة",
    "banner_title_ar" : "العنوان عربي",
    "banner_title_en" : "العنوان انجليزي",
    "banner_details_ar" : "الوصف عربي",
    "banner_details_en" : "الوصف انجليزي",
    "banner_image" : "الصورة",
    "banner_key" : "المفتاح",
    "banner_sort_id" : "الترتيب",
    "banner_url" : "الرابط",
    "banner_btn_title_ar" : "نص الزر عربي",
    "banner_btn_title_en" : "نص الزر انجليزي",
    "banner_status" : "الحالة",
    "about" : "من نحن",
    "about_plural" : "من نحن",
    "about_title_ar" : "العنوان عربي",
    "about_title_en" : "العنوان انجليزي",
    "about_details_ar" : "الوصف عربي",
    "about_details_en" : "الوصف انجليزي",
    "about_image" : "الصورة",
    "about_type" : "النوع",
    "about_icon" : "الايقونة",
    "about_status" : "الحالة",
    "about_url" : "الرابط",
    "feature" : "المميزات",
    "feature_plural" : "المميزات",
    "feature_title_ar" : "العنوان عربي",
    "feature_title_en" : "العنوان انجليزي",
    "feature_details_ar" : "الوصف عربي",
    "feature_details_en" : "الوصف انجليزي",
    "feature_image" : "الصورة",
    "feature_type" : "النوع",
    "feature_icon" : "الايقونة",
    "feature_status" : "الحالة",
    "feature_url" : "الرابط",
    "booking_step" : "خطوات الحجز",
    "booking_step_plural" : "خطوات الحجز",
    "booking_step_title_ar" : "العنوان عربي",
    "booking_step_title_en" : "العنوان اجليزي",
    "booking_step_details_ar" : "الوصف عربي",
    "booking_step_details_en" : "الوصف انجليزي",
    "booking_step_image" : "الصورة",
    "booking_step_type" : "النوع",
    "booking_step_icon" : "الايقونة",
    "booking_step_status" : "الحالة",
    "booking_step_url" : "الرابط",
    "gender_anyone" : "لافرق",
    "page_front" : "صفحات الموقع",
    "page_front_plural" : "ادارة صفحات الوقع",
    "page_front_title" : "العنوان",
    "page_front_slug" : "Slug",
    "page_front_content" : "المحتوى",
    "page_front_image" : "الصورة",
    "page_front_type" : "نوع الصفحة",
    "page_front_sort_id" : "الترتيب",
    "page_front_meta_title" : "عنوان محركات البحث",
    "page_front_meta_description" : "وصف محركات البحث",
    "page_front_keywords" : "الكلمات المفتاحية",
    "page_front_meta_image" : "صور محركات البحث",
    "page_front_status" : "الحالة",
    "articles_category" : "فئات المقالات",
    "articles_category_plural" : "فئات المقالات",
    "articles_category_title_ar" : "العنوان عربي",
    "articles_category_title_en" : "العنوان انجليزي",
    "articles_category_slug" : "Slug",
    "articles_category_abstract_ar" : "الوصف عربي",
    "articles_category_abstract_en" : "الوصف انجليزي",
    "articles_category_image" : "الصورة",
    "articles_category_status" : "الحالة",
    "article" : "مقالة",
    "article_plural" : "المقالات",
    "article_title_ar" : "العنوان عربي",
    "article_title_en" : "العنوان انجليزي",
    "article_slug" : "Slug",
    "article_description_ar" : "الوصف عربي",
    "article_description_en" : "الوصف انجليزي",
    "article_author_id" : "الكاتب",
    "article_tags" : "Tags",
    "article_views" : "عدد المشاهدات",
    "article_likes" : "الإعجابات",
    "article_excerpt" : "نص مختصر",
    "article_image" : "الصورة الرئيسية",
    "article_banner" : "الغلاف",
    "article_meta_description_ar" : "وصف عربي لمحركات البحث",
    "article_meta_description_en" : "وصف انجليزي لمحركات البحث",
    "article_is_featured" : "مقال مميز",
    "article_status" : "الحالة",
    "feedback" : "تقييم",
    "feedback_plural" : "التقييمات",
    "feedback_avg_rating" : "محتوى التقييم",
    "feedback_comment" : "نص التقييم",
    "feedback_patient_id" : "رقم المريض",
    "feedback_keep_anonymous" : "الحفظ كا تعليق مجهول.",
    "feedback_status" : "الحالة",
    "feedback_user_id" : "العميل",
    "is_free" : "مجانية",
    "type_package" : "نوع الباقة",
    "monthly" : "شهرياً",
    "yearly" : "سنوي",
    "six_month" : "6 اشهر",
    "featured" : "مميز",
    "billed_annually" : "دفع سنوى",
    "discount" : "التخفيض",
    "buy_now" : "شراء الآن",
    "message_create_account_success" : "تم انشاء الحساب بنجاح، سنقوم بمراجعة ملفك الشخصي والتواصل معك في أقرب وقت ممكن!",
    "message_notifcation_new_register" : "يوجد لديك طلب اشتراك",
    "transaction_failed_message" : "فشلت عملية الدفع ، يرجى  الاتصال بدعم الموقع.",
    "Regards" : "مع أطيب التحيات،",
    "HTTPSActivation" : "تفعيل HTTPS",
    "MaintenanceMode" : "وضع الصيانة",
    "WalletSystemActivation" : "تفعيل نظام المحفظة",
    "EmailVerification" : "تأكيد بواسطة البريد الالكتروني",
    "ConfigureNow" : "ضبط الخيارات",
    "configure_message_paymentmethods" : "تحتاج إلى تهيئة الاعداد  بشكل صحيح لتمكين هذه الميزة",
    "SocialMediaLogin" : "تسجيل الدخول   عبر وسائل التواصل الاجتماعي",
    "loginbyFacebook" : "تسجيل الدخول عن طريق الفيسبوك",
    "tax_amount" : "ضريبة القيمة المضافة",
    "subtotal" : "المجموع",
    "total" : "الاجمالي النهائي",
    "mywallet" : "محفظتي",
    "Insufficientbalance" : "رصيد غير كاف",
    "Pay_with_wallet" : "ادفع بالمحفظة",
    "note_bank_transfer_fees" : "بإمكانكم دفع رسوم الجلسة عن طريق التحويل لحساب مؤسسة غيَّر Ghier  في مصرف الراجحي ( 0000000000 ) ثم بعد ذلك إرسال إيصال التحويل على الواتس اب (  +966 55 555 5555) + البريد الالكتروني المسجل ، وسيقوم بعد ذلك فريق غيَّر Ghier بتاكيد الطلب في أسرع وقت .",
    "yourbalanceisinsufficient" : "رصيدك غير كافي الرجاء  اعادة شحن المحفظة الخاصة بك",
    "SelectPaymentOption" : "يجب  اختيار وسيلة  الدفع؟",
    "Havecouponcode" : "هل لديك كوبون؟ أدخل هنا.",
    "ChangeCoupon" : "تغيير الكوبون",
    "backtohome" : "العودة إلى الرئيسية",
    "Iagree" : "أنا أوافق على",
    "needtoagree" : "يجب أن توافق على الشروط والسياسات للتمكن من اكمال العملية.",
    "benefit_type_message" : "المستفيد من الاستشارة شخص اخر",
    "statement_session_transaction" : "مقابل تسديد رسوم جلسة (:appointment)  المستشار /  :consultant",
    "unpaid" : "غير مدفوع",
    "paid" : "تم الدفع",
    "stripe" : "البطاقة الائتمانية",
    "paypal" : "باي بال",
    "cash" : "cash",
    "wallet" : "المحفظة",
    "view_all" : "عرض الكل",
    "tomorrow" : "غداً",
    "successTitele" : "قصة نجاحنا",
    "about_ragmyat" : "عن رقميات",
    "about_us" : "من نحن",
    "why_us" : "لماذا رقميات؟",
    "methodology" : "منهجيتنا",
    "sahlt" : "رقميات التحول سهلت عليك الموضوع",
    "methodologyTitele" : " منهجية مؤسسة رقميات التحول ",
    "howWork" : "كيف نعمل",
    "distinction" : "مايميزنا",
    "distinctionTitele" : "تميزت رقميات في اعمالها ",
    "skills" : " مهاراتنا ",
    "digitalTransformation" : " التحول الرقمي ",
    "applicationDevelopment" : " تطوير التطبيقات ",
    "rowadAltahol" : " رواد التحول الرقمي  ",
    "terms_tonditions" : "الشروط و الأحكام",
    "Privacypolicy" : "سياسة الخصوصية",
    "Copyrights" : "Copyrights",
    "servicesTitele" : " خدمات وحلول رقميات ",
    "projects" : " مشاريعنا  ",
    "contact_us" : "تواصل معنا",
    "contact_usFooter" : "ابقى على تواصل معنا",
    "footerLinks" : "روابط تهمك",
    "FollowUs" : "تابعنا",
    "terms_conditions" : "الشروط والاحكام",
    "complete_payment" : "أكمل عملية الدفع",
    "wallet_balance" : "رصيد المحفظة",
    "payment" : "الدفع",
    "payment_plural" : "الفواتير",
    "payment_uuid" : "Uuid",
    "payment_user_id" : "المستخدم",
    "payment_coupon_id" : "كوبون التخفيض",
    "payment_package_id" : "باقات",
    "payment_method_id" : "وسيلة الدفع",
    "payment_token" : "Token",
    "payment_payment_id" : "Payment Id",
    "payment_charge_id" : "Charge Id",
    "payment_refund_id" : "Refund Id",
    "payment_payment_name" : "Payment Name",
    "payment_payment_country" : "Payment Country",
    "payment_payment_state" : "Payment State",
    "payment_payment_message" : "Payment Message",
    "payment_payment_cnumber" : "Payment Cnumber",
    "payment_payment_phone" : "Payment Phone",
    "payment_payment_address" : "العنوان",
    "payment_payment_email" : "Payment Email",
    "payment_payment_status_return" : "Payment Status Return",
    "payment_payment_amount" : "مبلغ الدفع",
    "payment_payment_fee" : "Payment Fee",
    "payment_payment_currency" : "Payment Currency",
    "payment_payment_refunded" : "Payment Refunded",
    "payment_payment_amountFormat" : "Payment Amountformat",
    "payment_payment_fee_format" : "Payment Fee Format",
    "payment_payment_refunded_format" : "Payment Refunded Format",
    "payment_payment_ip" : "Payment Ip",
    "payment_payment_callbackURL" : "Payment Callbackurl",
    "payment_payment_created_at" : "Payment Created At",
    "payment_payment_apdated_at" : "Payment Apdated At",
    "payment_payment_cardtype" : "Payment Cardtype",
    "payment_payment_cardcompany" : "Payment Cardcompany",
    "payment_payable" : "المبلغ المستحق",
    "payment_tax" : "الضريبة",
    "payment_commission" : "Commission",
    "payment_discount" : "التخفيض",
    "payment_total" : "الاجمالي",
    "payment_subtotal" : "رسوم الجلسة",
    "payment_description" : "الوصف",
    "payment_payment_status" : "حالة الدفع",
    "payment_payment_method" : "وسيلة الدفع",
    "coupon" : "كوبون التخفيض",
    "coupon_plural" : "كوبونات التخفيض",
    "coupon_coupon_code" : "كوبون التخفيض",
    "coupon_coupon_type" : "نوع الكوبون",
    "coupon_discount" : "نسبة التخفيض",
    "coupon_image" : "صورة الكوبون",
    "coupon_coupon_limit" : "استخدام محدد",
    "coupon_coupon_user_limit" : "عدد المستخدمين للكوبون",
    "coupon_usage" : "الاستخدام",
    "coupon_avail_from" : "من تاريخ",
    "coupon_expiration" : "تاريخ الانتهاء",
    "coupon_status" : "حالة الكوبون",
    "discount_type_amount" : "قيمة",
    "percent" : "نسبة",
    "fees" : "عمولة الدفع",
    "transaction_type" : "النوع (مدين / دائن)",
    "transaction_type_plural" : "انواع الحركات المالية",
    "transaction_type_desc" : "ادارة انواع الحركة المالية",
    "transaction_type_edit" : "تعديل نوع الحركة المالية",
    "transaction_type_table" : "قائمة الحركات المالية",
    "transaction_type_create" : "انشاء نوع الحركة المالية",
    "transaction_type_name" : "اسم المستند",
    "transaction" : "الحركة المالية",
    "transaction_plural" : "الحركة المالية",
    "transaction_desc" : "ادارة الحركة المالية",
    "transaction_edit" : "تعديل الحركة المالية",
    "transaction_table" : "قائمة الحركات",
    "transaction_create" : "انشاء حركة مالية",
    "transaction_user_id" : "معرف المستخدم",
    "transaction_end_user_id" : "الطرف الاخر (المستلم)",
    "transaction_currency_id" : "العملة",
    "transaction_payment_method_id" : "طريقة الدفع",
    "transaction_merchant_id" : "معرف التاجر",
    "transaction_bank_id" : "البنك",
    "transaction_file_id" : "الملف",
    "transaction_uuid" : "Uuid",
    "transaction_refund_reference" : "مرجع الاسترجاع",
    "transaction_transaction_reference_id" : "مرجع الحركة",
    "transaction_transaction_type_id" : "نوع الحركة",
    "transaction_user_type" : "نوع المستخدم",
    "transaction_email" : "البريد الاكتروني",
    "transaction_phone" : "الجوال",
    "transaction_subtotal" : "المجموع الفرعي",
    "transaction_percentage" : "النسبة المئوية",
    "transaction_charge_percentage" : "العمولة نسبة",
    "transaction_charge_fixed" : "عمولة ثابتة",
    "transaction_total" : "الاجمالي",
    "transaction_note" : "ملاحظات",
    "transaction_status" : "حالة الحركة",
    "invoice_id" : "رقم الفاتورة",
    "agent_id" : "وكيل السداد",
    "Success" : "مكتملة",
    "Refund" : "مرتجع",
    "Cancelled" : "ملغية",
    "invoice" : "الفاتورة",
    "invoice_plural" : "الفواتير",
    "invoice_uuid" : "Uuid",
    "invoice_transaction_id" : "Transaction Id",
    "invoice_package_id" : "الباقة",
    "invoice_invoice_ref" : "مرجع الفاتورة",
    "invoice_invoice_type" : "نوع الفاتورة",
    "invoice_invoice_data" : "بيانات الفاتورة",
    "invoice_subtotal" : "المجموع",
    "invoice_discount" : "الخصم",
    "invoice_tax" : "الضريبة",
    "invoice_total" : "الاجمالي الكلي",
    "invoice_description" : "الوصف",
    "invoice_created_by" : "انشى بواسطة",
    "invoice_user_id" : "المستخدم",
    "invoice_payment_date" : "تاريخ الدفع",
    "invoice_invoice_url" : "رابط الفاتورة",
    "invoice_payment_detail" : "تفاصيل الدفع",
    "invoice_payment_status" : "حالة الدفع",
    "invoice_payment_method" : "وسيلة الدفع",
    "invoice_status" : "الحالة",
    "invoice_accepted_by" : "تم القبول بواسطة",
    "invoice_accepted_at" : "تاريخ القبول",
    "payment_method_type" : "النوع",
    "payment_method_uuid" : "Uuid",
    "payment_method_title" : "وسيلة الدفع",
    "payment_method_description" : "الوصف",
    "payment_method_bank_info" : "معلومات البنك",
    "payment_method_photo" : "Photo",
    "created_at" : "انشئ في",
    "stock_branch_id" : "الفرع",
    "pending" : "قيد الانتظار",
    "failed" : "فشل",
    "canceled" : "ملغي",
    "execute_successfully" : "تم العملية بنجاح",
    "stock_management" : "ادارة المخزون",
    "upload_stock" : "رفع المخزون",
    "special_offers_creates" : "اضافة عروض ",
    "country_plural" : "الدول",
    "country_short_name" : "الاسم المختصر",
    "country_name_ar" : "الاسم عربي",
    "country_name_en" : "الاسم انجليزي",
    "country_iso3" : "Iso3",
    "country_number_code" : "رقم الكود",
    "country_phone_code" : "كود الاتصال",
    "country_latitude" : "GPS-X",
    "country_longitude" : "GPS-Y",
    "country_image" : "الصورة",
    "country_status" : "الحالة",
    "myoffers" : "عروضي",
    "orders_plural" : "أوامر الشراء المستلمة",
    "orders_uuid" : "Uuid",
    "orders_user_id" : "المستخدم",
    "orders_supplier_id" : "التاجر",
    "orders_supplier_owner_id" : "التاجر المالك",
    "orders_branch_id" : "الفرع",
    "orders_offer_id" : "العرض",
    "orders_name" : "الاسم",
    "orders_phone" : "رقم الهاتف",
    "orders_address" : "العنوان",
    "orders_method_id" : "وسيلة الدفع",
    "orders_coupon_id" : "كوبون التخفيض",
    "orders_tax" : "الضريبة",
    "orders_total" : "الاجمالي",
    "orders_subtotal" : "المجموع",
    "orders_discount" : "التخفيض",
    "orders_note" : "ملاحظات",
    "orders_qty_change" : "تعدلت الكمية",
    "orders_accept_qty" : "قبول الكمية",
    "orders_quantity_old" : "الكمية قبل التعديل",
    "orders_quantity" : "الكمية",
    "orders_status" : "الحالة",
    "stock_history" : "حركة المخزن",
    "stock_history_plural" : "حركة المخازن",
    "stock_history_supplier_id" : "التاجر",
    "stock_history_stock_id" : "الصنف",
    "stock_history_history_type_id" : "نوع الحركة",
    "stock_history_order_item_id" : "القطعة",
    "stock_history_branch_id" : "الفرع",
    "stock_history_quantity" : "الكمية",
    "stock_history_price" : "السعر",
    "stock_history_type" : "انواع حركة المخازن",
    "stock_history_created_by" : "انشئ بواسطة",
    "stock_history_type_plural" : "انواع حركة المخازن",
    "stock_history_type_name" : "الاسم",
    "stock_history_type_uuid" : "Uuid",
    "stock_history_type_description" : "الوصف",
    "stock_history_type_status" : "الحالة",
    "types_stock_plural" : "التصنيف",
    "types_stock_name_ar" : "الاسم عربي",
    "types_stock_name_en" : "الاسم انجليزي",
    "types_stock_note" : "ملاحظة",
    "manufacturing_countries" : "بلد الصنع",
    "manufacturing_countries_plural" : "الدول المصنعه لقطع الغيار",
    "manufacturing_countries_name_ar" : "الاسم عربي",
    "manufacturing_countries_name_en" : "الاسم انجليزي",
    "manufacturing_countries_note" : "ملاحظة",
    "cart" : "سلة اومر الشراء",
    "cart_plural" : "سلة اومر الشراء",
    "cart_user_id" : "المستخدم",
    "cart_supplier_id" : "التاجر",
    "cart_stock_id" : "القطعة",
    "cart_offer_id" : "العرض",
    "cart_offer_item_id" : "رقم القطعة (العرض)",
    "cart_note_shipping" : "لايوجد توصيل",
    "cart_price" : "السعر",
    "cart_tax" : "الضريبة",
    "cart_discount" : "التخفيض",
    "cart_delivery_data" : "بيانات التوصيل",
    "cart_quantity" : "الكمية",
    "complated" : "مكتمل",
    "rejected" : "مرفوض",
    "accepted" : "تم القبول",
    "deliverylocation" : "طريقة التوصيل",
    "shipped" : "تم الشحن",
    "purchases" : "أوامر الشراء المرسلة",
    "period_time" : "الفترة الزمنية",
    "start_wholesale_price" : "اقل كمية لسعر الجملة",
    "viewin_public_store" : "العرض في المتجر العام",
    "myoffers_list" : "قائمة عروضي",
    "myoffers_plural" : "ادارة عروضي",
    "order_spare_part" : "الطلبات العاجلة",
    "order_spare_part_plural" : "الطلبات العاجلة",
    "order_spare_part_uuid" : "Uuid",
    "order_spare_part_user_id" : "المستخدم",
    "order_spare_part_branch_id" : "فرع الاستلام",
    "order_spare_part_supplier_id" : "التاجر",
    "order_spare_part_anufacturing_countries_id" : "بلد الصنع",
    "order_spare_part_brand_id" : "العلامة التجارية",
    "order_spare_part_types_stock_id" : "النوع",
    "order_spare_part_part_number" : "رقم القطعة",
    "order_spare_part_payment_method" : "وسيلة الدفع",
    "order_spare_part_deliverylocation" : "طريقة التوصيل",
    "order_spare_part_tax" : "الضريبة",
    "order_spare_part_total" : "الاجمالي",
    "order_spare_part_subtotal" : "المجموع",
    "order_spare_part_discount" : "التخفيض",
    "order_spare_part_note" : "ملاحظات",
    "order_spare_part_quantity" : "الكمية",
    "order_spare_part_suppliers_ref" : "التجار المختارين",
    "order_spare_part_status" : "الحالة",
    "supplier_accept_id" : "التاجر المستلم للطلب",
    "unconditional" : "غير مشروط",
    "Youdonothavethepermissions" : "لايوجد لديك الصلاحية المناسبة",
    "order_spare_part_send" : "القطع المطلوبة",
    "order_spare_part_send_plural" : "القطع المطلوبة",
    "order_spare_part_send_uuid" : "Uuid",
    "order_spare_part_send_user_id" : "المستخدم",
    "order_spare_part_send_branch_id" : "فرع الاستلام",
    "order_spare_part_send_supplier_id" : "التاجر",
    "order_spare_part_send_anufacturing_countries_id" : "بلد الصنع",
    "order_spare_part_send_brand_id" : "العلامة التجارية",
    "order_spare_part_send_types_stock_id" : "النوع",
    "order_spare_part_send_part_number" : "رقم القطعة",
    "order_spare_part_send_payment_method" : "وسيلة الدفع",
    "order_spare_part_send_deliverylocation" : "طريقة التوصيل",
    "order_spare_part_send_tax" : "الضريبة",
    "order_spare_part_send_total" : "الاجمالي",
    "order_spare_part_send_subtotal" : "المجموع",
    "order_spare_part_send_discount" : "التخفيض",
    "order_spare_part_send_note" : "ملاحظات",
    "order_spare_part_send_quantity" : "الكمية",
    "order_spare_part_send_suppliers_ref" : "التجار المختارين",
    "order_spare_part_send_status" : "الحالة",
    "supplier_company_name_en" : "الاسم  انجليزي ",
    "mobile_verified" : "التحقق من الجوال",
    "email_verified" : "التحقق من البريد",
    "media_select" : "من مكتبة الوسائط",
    "approved" : "معتمد",
    "disapproved" : "غير معتمد",
    "RestorePassword" : " استعادة كلمة المرور",
    "VerifyYourEmailAddress" : " تحقق من عنوان بريدك الإلكتروني  ",
    "If_you_did_not_receive_the_email" : "  إذا لم تتلق البريد الإلكتروني ",
    "A_fresh_verification_link_has_been_sent_to_your_email_address" : "  تم إرسال رابط تحقق جديد إلى عنوان بريدك الإلكتروني  ",
    "BankName" : " اسم البنك ",
    "SenderName" : " اسم المرسل ",
    "NumberOfRemittance" : "  رقم الحوالة ",
    "BondImage" : "   صورة السند ",
    "PlatformStats" : " احصائيات المنصة ",
    "PackageSettings" : " اعدادات الباقات",
    "MerchantData" : "  بيانات خاصة بالتاجر ",
    "The_maximum_quantity_offered_by_merchants" : " الحد الاعلى لعرض الكمية عند التجار",
    "SetQuantify" : " احصائيات المنصة ",
    "Your_request_has_been_sent_we_will_respond_as_soon_as_possible" : "  لقد تم ارسال طلبك سوف يتم الرد في اقرب وقت  ",
    "Your_request_has_been_accepted_you_can_use_the_account" : " لقد تم قبول طلبك تستطيع بدء استخدام الحساب  ",
    "Your_request_has_been_rejected_because" : " لقد تم رفض طلبك وذلك بسبب   ",
    "You_can_select_your_account_type_only_once_and_you_cannot_change_the_account_type" : " تستطيع تحديد نوع حسابك فقط مره واحده ولاتستطيع تغيير نوع الحساب  ",
    "AccountType" : " نوع الحساب  ",
    "DefaultAccount" : " الحساب الافتراضي  ",
    "Merchant" : "  تاجر ",
    "Mediator" : " وسيط  ",
    "GeneralUser" : "  المستخدم العام ",
    "SparePartsFactory" : "  مصنع قطع غيار ",
    "TradeName" : " الاسم التجاري ",
    "The_status_of_receiving_offers_from_merchants" : " حالة استقبال عروض التجار",
    "The_status_of_receiving_offers" : " حالة استقبال العروض  ",
    "Your_data_has_been_uploaded_do_you_want_to_modify_please_contact_technical_support" : " لقد تم رفع البيانات الخاصه بك هل تريد التعديل يرجئ التواصل مع الدعم الفني  ",
    "commercial_activities" : " النشاط التجاري  ",
    "commercial_registration_no" : " رقم السجل التجاري  ",
    "place_of_issue" : " مكان الاصدار  ",
    "expiry_date" : "  تاريخ الانتهاء  ",
    "taxnumber" : " الرقم الضريبي ",
    "attachments" : " المرفقات ",
    "file_name" : " اسم الملف ",
    "attachments_uploaded" : " تم رفع المرفقات",
    "administrator_data" : " بيانات المسؤول",
    "approval_request" : " طلب اعتماد",
    "You_can_request_account_approval_through_the_following_button" : "  الان يمكنك طلب اعتماد الحساب من خلال الزر التالي",
    "You_cannot_request_account_approval_until_the_data_is_completed_and_your_attachments_are_reviewed" : " لايمكنك طلب اعتماد الحساب حتى يتم استكمال البيانات والاطلاع على المرفقات الخاصة بك",
    "set_quantify" : " حدد الكمية",
    "minimum_order_quantity" : "اقل كميه لطلب القطعه",
    "start_of_calculating_the_wholesale_price_of_the_piece" : " بداية احتساب سعر الجملة للقطعة",
    "alert_when_adding_widget" : " تنبيه عند اضافة القطعة رقم",
    "alert_when_adding_any_part_of_the_brand" : " تنبيه عند اضافة اي قطعه تابعه للعلامه التجاريه",
    "select_the_default_branch" : " حدد الفرع الافتراضي",
    "select_the_factory_default_country" : "   حدد بلد المصنع الافتراضي",
    "select_the_default_classification" : "   حدد التصنيف الافتراضي",
    "possibility_to_receive_merchant_offers" : "  امكانية استقبال عروض التجار",
    "possibility_to_receive_orders" : " امكانية استقبال الطلبات",
    "direct_dialogue_with_merchants" : "  الحوار المباشر مع التجار",
    "notifications_general" : " التنبيهات بالمجمل",
    "city_country_id" : "البلد",
    "city_name_ar" : "الاسم Ar",
    "city_name_en" : "الاسم  En",
    "city_latitude" : "GPS-X",
    "city_longitude" : "GPS-Y",
    "city_arealocation" : "المنطقة",
    "city_image" : "الصورة",
    "city_status" : "الحالة",
    "service" : "Service",
    "service_plural" : "ادارة الخدمات",
    "service_id" : "Id",
    "service_title_ar" : "Title Ar",
    "service_title_en" : "Title En",
    "service_slug" : "Slug",
    "service_description_ar" : "Description Ar",
    "service_description_en" : "Description En",
    "service_tags" : "Tags",
    "service_views" : "Views",
    "service_likes" : "Likes",
    "service_excerpt" : "Excerpt",
    "service_image" : "Image",
    "service_banner" : "Banner",
    "service_meta_description_ar" : "Meta Description Ar",
    "service_meta_description_en" : "Meta Description En",
    "service_is_featured" : "Is Featured",
    "service_status" : "Status",
    "services_plural" : " ادارة الخدمات",
    "services_title_ar" : " اسم الخدمة عربي",
    "services_title_en" : "اسم الخدمة انجليزي",
    "services_slug" : " سلوقن ",
    "services_description_ar" : "وصف الخدمة عربي",
    "services_description_en" : "وصف الخدمة انجليزي",
    "services_tags" : " كلمات دلالية ",
    "services_views" : "مشاهده",
    "services_likes" : "اعجاب",
    "services_excerpt" : "نص مختصر",
    "services_image" : "ايقونة الخدمة",
    "services_banner" : "صورة الخدمة",
    "services_meta_description_ar" : "وصف عربي لمحركات البحث",
    "services_meta_description_en" : "وصف انجليزي لمحركات البحث",
    "services_is_featured" : "مميزة",
    "services_status" : "الحالة",
    "client" : "ادارة العملاء",
    "client_plural" : "عملائنا",
    "client_title_ar" : "اسم العميل بالعربي",
    "client_title_en" : "اسم العميل بالانجليزي",
    "client_image" : "صورة",
    "client_url" : "الرابط",
    "client_status" : "حالة",
    "project" : "ادارة مشاريعنا",
    "project_plural" : "مشاريعنا",
    "project_title_ar" : "اسم المشروع بالعربية",
    "project_title_en" : "اسم المشروع بالإنجليزية",
    "project_slug_ar" : "السلوقن بالعربية",
    "project_slug_en" : "السلوقن بالإنجليزية",
    "project_client_ar" : "العميل ",
    "project_client_en" : "العميل بالإنجليزية",
    "project_description_ar" : "وصف المشروع ",
    "project_description_en" : "وصف المشروع بالانجليزية",
    "project_advantages_ar" : "مزايا المشروع  ",
    "project_advantages_en" : "مزايا المشروع بالانجليزية",
    "project_tags" : " رابط المشروع URL",
    "project_views" : "مشاهدات",
    "project_likes" : "اعجابات",
    "project_phone" : "هاتف",
    "project_email" : "البريد الالكتروني",
    "project_location" : "عنوان المشروع",
    "project_project_delivery" : "تسليم المشروع",
    "project_image" : "صورة المشروع",
    "project_banner" : "البنر",
    "project_meta_description_ar" : "وصف عربي لمحركات البحث",
    "project_meta_description_en" : "وصف انجليزي لمحركات البحث",
    "project_is_featured" : "ظهور في الرئيسة",
    "project_status" : "الحالة",
    "wibsiteVisit" : "زيارة الموقع ",
    "our_new_type" : "نوع الصفحة",
    "our_new" : "جديدنا",
    "our_new_plural" : "ادارة جديدنا",
    "our_new_title" : "العنوان",
    "our_new_slug" : "السلوقن",
    "our_new_content" : "المحتوى",
    "our_new_image" : "الصورة",
    "our_new_attribute" : "الترميز",
    "our_new_sort_id" : "الترتيب",
    "our_new_meta_title" : "عنوان محركات البحث",
    "our_new_meta_description" : "وصف محركات البحث",
    "our_new_keywords" : "الكلمات المفتاحية",
    "our_new_meta_image" : "صورة  البنر ",
    "our_new_status" : "الحالة",
    "raqeeb" : "نظام رقيب",
    "qFatora" : "كيو فاتورة",
    "partsRaqeeb" : " اقسام البرنامج ",
    "getAquote" : "أحصل على عرض أسعار",
    "featureQFatora" : " تميزنا ",
    "cpanelQFatora" : " لوحة تحكم شاملة ",
    "PackagesQFatora" : "باقاتنا ",
    "solutionsFatora" : " حلولنا ",
    "commercialSectorsFatora" : " القطاعات التجاریة ",
    "Monthly" : "شهري ",
    "Month" : " شهرياً ",
    "Yearly" : "سنوي ",
    "Year" : " سنوياً ",
    "proparties" : " خصائص النظام "
}
