import { createI18n } from "vue-i18n";

let locale;
let fallbackLocale;

function loadLocaleMessages() {
  const locales = require.context("./lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

if (typeof process !== 'undefined' && process.env) {
  locale = process.env.VUE_APP_I18N_LOCALE || 'ar';
  fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar';
} else {
  // Provide default values for non-Node.js environments
  locale = 'ar';
  fallbackLocale = 'ar';
}

export default createI18n({
  locale,
  fallbackLocale,
  messages: loadLocaleMessages(),
});
