<template>
  <section
    :style="{ backgroundImage: `url('${patternVue2}')` }"
    class="white-bg position-relative text-center bg-img"
  >
    <div class="pattern-3">
      <img class="img-fluid rotateme" :src="`${patternVue3}`" alt />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 mx-auto" v-if="servicesContent">
          <div class="section-title">
            <h6>{{ $t('services') }}</h6>
            <h2 class="title">{{ servicesContent.title }}</h2>
            <p v-html="servicesContent.content"></p>
          </div>
        </div>
      </div>
      <div class="row mx-auto services">
        <div
          v-for="(service, index) in services"
          :key="index"
          :class="{'col-lg-5 col-md-4': index+1 === 3 ,'col-lg-5 col-md-4 mr-auto': index+1 === 4, 'col-lg-6 col-md-6': index+1 !== 3 && index+1 !== 4}"
        >
          <div class="featured-item" :class="`style-3 serv${index + 1}`">
            <div class="featured-icon">
              <div class="hexagon mx-auto text-center">
                <span>
                  <img class="img-fluid" :src="service.image" :alt="service.title_ar" />
                </span>
              </div>
            </div>
            <div class="featured-title">
              <h5>
                <router-link :to="`/Servese/${service.id}`">{{ service.title_ar }}</router-link>
              </h5>
            </div>
            <div class="featured-desc">
              <p>{{ service.slug }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import patternVue3 from "@/assets/frontend/plugins/images/pattern/03.png";
import patternVue2 from "@/assets/frontend/plugins/images/pattern/02.png";
export default {
  name: "ServesesSection",
  props: {
    indexData: {
      type: Object,
      default: () => ({})
    },
    msg: String
  },
  data() {
    return {
      modifiedIndexData: {},
      patternVue3,
      patternVue2,
    };
  },
  computed: {
    services() {
      return this.indexData.services;
    },
    servicesContent() {
      return this.indexData.servicesContent;
    }
  },
  mounted() {
    // Modify the indexData prop and assign it to the modifiedIndexData data property
    this.modifiedIndexData = { ...this.indexData };
  }
};
</script>

