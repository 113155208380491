<template>
<div>
  <div class="page-wrapper rtl" dir="rtl" lang="ar">
    <!-- preloader start -->

    <div id="ht-preloader" v-if="showPreloader">
      <div class="loader clear-loader">
        <div class="loader-text">{{ title }}</div>
        <div class="loader-dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
    
         </div>
    </div>

    <!-- preloader end -->
    <MenueSection :SettingData="SettingData" v-if="SettingData" />
    <router-view :SettingData="SettingData" v-if="SettingData" />
    <FooterSection :SettingData="SettingData" v-if="SettingData" />
  </div>
  <!-- page wrapper end -->

  <!--back-to-top start-->
  <div class="scroll-top">
    <a class="smoothscroll" href="#top"><i class="flaticon-upload"></i></a>
  </div>


  </div>
</template>
<script>
import FooterSection from "@/components/FooterSection.vue";
import MenueSection from "@/components/MenueSection.vue";
// import axios from "axios";


export default {
   
     data() {
    return {
      title: " رقميات التحول",
      SettingData: undefined,
      showPreloader: true,
    };
  },
  created() {
    this.$axios
      .get("Setting")
      .then((response) => {
        this.SettingData = response.data;
        // console(JSON.parse(JSON.stringify(this.indexData.aboutinfo)));
      })
      .catch((error) => {
        console.error(error);
      });
  },
  components: {
    MenueSection,
    FooterSection,
  },
  mounted() {
    // Simulate the page loading process
    setTimeout(() => {
      this.showPreloader = false;
    }, 2000); // Adjust the timeout value as needed
    // theme.initialize(); 
    // magnificPopup.initialize(); 
    // owlcarousel.initialize(); 
    // counter.initialize(); 
    // countdown.initialize(); 
    // isotopePkgd.initialize(); 
    // tweenmax.initialize(); 
    // parallax.initialize(); 
    // wow.initialize(); 
    // particles.initialize(); 
    // themeApp.initialize(); 
    // scriptMov.initialize(); 
    // themeScript.initialize(); 
   
  },

};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Flaticon";
  src: url("@/assets/frontend/plugins/fonts/Flaticon.eot");
  src: url("@/assets/frontend/plugins/fonts/Flaticon.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/frontend/plugins/fonts/Flaticon.woff") format("woff"),
    url("./assets/frontend/plugins/fonts/Flaticon.ttf") format("truetype"),
    url("./assets/frontend/plugins/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./assets/frontend/plugins/fonts/Flaticon.svg#Flaticon")
      format("svg");
  }
}

@font-face {
  font-family: "themify";
  src: url("@/assets/frontend/plugins/fonts/themify.eot?-fvbane");
  src: url("@/assets/frontend/plugins/fonts/themify.eot?#iefix-fvbane")
      format("embedded-opentype"),
    url("@/assets/frontend/plugins/fonts/themify.woff?-fvbane") format("woff"),
    url("@/assets/frontend/plugins/fonts/themify.ttf?-fvbane")
      format("truetype"),
    url("@/assets/frontend/plugins/fonts/themify.svg?-fvbane#themify")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(@/assets/frontend/plugins/fonts/fa-brands-400.eot);
  src: url(@/assets/frontend/plugins/fonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(@/assets/frontend/plugins/fonts/fa-brands-400.woff2) format("woff2"),
    url(@/assets/frontend/plugins/fonts/fa-brands-400.woff) format("woff"),
    url(@/assets/frontend/plugins/fonts/fa-brands-400.ttf) format("truetype"),
    url(@/assets/frontend/plugins/fonts/fa-brands-400.svg#fontawesome)
      format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url(@/assets/frontend/plugins/fonts/fa-regular-400.eot);
  src: url(@/assets/frontend/plugins/fonts/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(@/assets/frontend/plugins/fonts/fa-regular-400.woff2) format("woff2"),
    url(@/assets/frontend/plugins/fonts/fa-regular-400.woff) format("woff"),
    url(@/assets/frontend/plugins/fonts/fa-regular-400.ttf) format("truetype"),
    url(@/assets/frontend/plugins/fonts/fa-regular-400.svg#fontawesome)
      format("svg");
}

.far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(@/assets/frontend/plugins/fonts/fa-solid-900.eot);
  src: url(@/assets/frontend/plugins/fonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(@/assets/frontend/plugins/fonts/fa-solid-900.woff2) format("woff2"),
    url(@/assets/frontend/plugins/fonts/fa-solid-900.woff) format("woff"),
    url(@/assets/frontend/plugins/fonts/fa-solid-900.ttf) format("truetype"),
    url(@/assets/frontend/plugins/fonts/fa-solid-900.svg#fontawesome)
      format("svg");
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}

.fa,
.fas {
  font-weight: 900;
}

[class^="ti-"],
[class*=" ti-"] {
  font-family: "themify";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

